import { SVGProps } from "react";

export function CloseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={21}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.375 17.625C21.1875 18.375 21.1875 19.6875 20.375 20.4375C20 20.8125 19.5 21 19 21C18.4375 21 17.9375 20.8125 17.5625 20.4375L11 13.875L4.375 20.4375C4 20.8125 3.5 21 3 21C2.4375 21 1.9375 20.8125 1.5625 20.4375C0.75 19.6875 0.75 18.375 1.5625 17.625L8.125 11L1.5625 4.4375C0.75 3.6875 0.75 2.375 1.5625 1.625C2.3125 0.8125 3.625 0.8125 4.375 1.625L11 8.1875L17.5625 1.625C18.3125 0.8125 19.625 0.8125 20.375 1.625C21.1875 2.375 21.1875 3.6875 20.375 4.4375L13.8125 11.0625L20.375 17.625Z"
        fill={props.fill}
      />
    </svg>
  );
}
