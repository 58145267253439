/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, useEffect, useRef } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { CatalogItemImage } from "../../../../redeem/catalog-giftcard-redemption-info/catalog-item-list/catalog-item/catalog-item-image";
import { CatalogItemPointValue } from "../../../../redeem/catalog-giftcard-redemption-info/catalog-item-list/catalog-item/catalog-item-point-value";
import { RedemptionCatalogItemFragmentFragment } from "../../../../redeem/catalog-giftcard-redemption-info/catalog-item-list/redemption-catalog-item-fragment.generated";

export interface CatalogItemDetailProps {
  catalogItem: RedemptionCatalogItemFragmentFragment;
  onCancel: () => void;
  onSelect: () => void;
  setFooterHeight: (height: number) => void;
  footerType: "fixed" | "sticky";
}

export function CatalogItemDetail({
  onCancel,
  onSelect,
  catalogItem,
  setFooterHeight,
  footerType,
}: CatalogItemDetailProps) {
  const track = useTrack();
  const footerRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useFormatters();

  useEffect(() => {
    setFooterHeight(footerRef.current?.getBoundingClientRect().height || 0);
  }, [footerRef, setFooterHeight]);

  return (
    <>
      <div
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
          padding: 0 ${theme.spacing(2)};
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          <CatalogItemImage
            imageUrl={catalogItem.imageUrl}
            displayName={catalogItem.displayName}
            autoWidth
            height="116px"
          />

          <CatalogItemPointValue
            priceInPoints={catalogItem.priceInPoints ?? 0}
          />
        </div>
        {catalogItem.details && (
          <>
            <Typography
              variant="subtitle"
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              {formatMessage({
                defaultMessage: "Details",
                description: "Catalog item detail drawer > details heading",
              })}
            </Typography>
            <Typography variant="footnote">
              {catalogItem.details.split("\n").map((line, index) => (
                <Fragment key={index}>
                  {line}
                  {index < catalogItem.details!.split("\n").length && (
                    <div
                      css={(theme: AppTheme) => css`
                        content: "";
                        height: ${theme.spacing(1)};
                      `}
                    />
                  )}
                </Fragment>
              ))}
            </Typography>
          </>
        )}
      </div>
      <div
        ref={footerRef}
        css={(theme: AppTheme) => css`
          position: ${footerType};
          bottom: 0;
          display: flex;
          gap: ${theme.spacing(1)};
          width: 100%;
          background-color: ${theme.palette.background.paper};
          padding: ${theme.spacing(2)};
        `}
      >
        <Button
          variant="outlined"
          label={formatMessage({
            defaultMessage: "Cancel",
            description: "Catalog item detail drawer > cancel button",
          })}
          onClick={() => {
            onCancel();
            track("Redemption catalog detail modal clicked cancel", {
              itemId: catalogItem.id,
              itemPriceInPoints: catalogItem.priceInPoints,
              itemDisplayName: catalogItem.displayName,
            });
          }}
        />
        <Button
          color="secondary"
          label={formatMessage({
            defaultMessage: "Select",
            description: "Catalog item detail drawer > select button",
          })}
          onClick={() => {
            onSelect();
            track("Redemption catalog detail modal clicked select", {
              itemId: catalogItem.id,
              itemPriceInPoints: catalogItem.priceInPoints,
              itemDisplayName: catalogItem.displayName,
            });
          }}
        />
      </div>
    </>
  );
}
