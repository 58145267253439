import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type RedemptionCatalogItemFragmentFragment = (
  { __typename?: 'RedemptionCatalogItem' }
  & Pick<Types.RedemptionCatalogItem, 'id' | 'category' | 'displayName' | 'imageUrl' | 'details' | 'priceInPoints'>
);

export const RedemptionCatalogItemFragmentFragmentDoc = gql`
    fragment RedemptionCatalogItemFragment on RedemptionCatalogItem {
  id
  category
  displayName
  imageUrl
  details
  priceInPoints
}
    `;