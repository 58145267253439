import { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";

import { useLocalStorageSimpleObject } from "@rewards-web/shared/hooks/use-local-storage";
import { useWindowFocusHandler } from "@rewards-web/shared/hooks/use-window-focus-handler";
import { reportError } from "@rewards-web/shared/modules/error";

import { usePointBalance } from "../../../../../shared/modules/point-balance";
import { IconBadgeData } from "../navigation-icon-with-badge";
import { getDrawTabBadge, getRewardsTabBadge } from "./lib";
import { useRewardsAppTabBadgeDataQuery } from "./rewards-app-tab-badge-data.generated";

export function useNotificationBadgeData(): {
  rewardNotificationBadge: IconBadgeData | null;
  drawNotificationBadge: IconBadgeData | null;
  socialPostNotificationBadge: IconBadgeData | null;
} {
  const tabBadgeDataQuery = useRewardsAppTabBadgeDataQuery({
    onError: reportError,
  });
  useWindowFocusHandler({
    onFocused: () => tabBadgeDataQuery.refetch(),
  });

  const match = useMatch("/:route/*");
  const currentRoute = match?.params.route;

  const { computedBalance } = usePointBalance();

  const [
    rewardBadgeViewData,
    setRewardBadgeViewData,
  ] = useLocalStorageSimpleObject<{ lastViewedPointsRedeemed: number }>({
    key: "reward-badge-view-data",
  });

  const [
    drawBadgeViewData,
    setDrawBadgeViewData,
  ] = useLocalStorageSimpleObject<{
    lastViewedDrawId: string;
    lastViewedTickets: number;
  }>({
    key: "draw-badge-view-data",
  });

  const [
    socialPostBadgeViewData,
    setSocialPostBadgeViewData,
  ] = useState<IconBadgeData | null>(null);

  const rewardTabBadge = (() => {
    if (!tabBadgeDataQuery.data) {
      return null; // still loading query
    }
    if (computedBalance === undefined) {
      return null; // still loading points
    }
    const {
      organization: { minimumPointsNeededToRedeem },
      myRewardsUser: { pointsRedeemed: currentPointsRedeemed },
    } = tabBadgeDataQuery.data;

    return getRewardsTabBadge({
      computedBalance,
      minimumPointsNeededToRedeem,
      lastViewedPointsRedeemed:
        rewardBadgeViewData?.lastViewedPointsRedeemed ?? null,
      currentPointsRedeemed,
    });
  })();

  const drawTabBadge = ((): IconBadgeData | null => {
    if (!tabBadgeDataQuery.data) {
      return null; // still loading query
    }
    const {
      lastDrawData: lastDraw,
      currentCaribouSponsoredDraw: currentCaribouDraw,
      organization: {
        drawsEnabled: organizationRunDrawsEnabled,
        hasAccessToCaribouSponsoredDraws: caribouSponsoredDrawsEnabled,
      },
    } = tabBadgeDataQuery.data;

    if (organizationRunDrawsEnabled && lastDraw) {
      return getDrawTabBadge({
        drawHost: "organization",
        winnersAnnounced: lastDraw.winnersAnnounced,
        hasViewedWinners: lastDraw.myRewardsUserViewedWinners,
      });
    } else if (caribouSponsoredDrawsEnabled && currentCaribouDraw) {
      return getDrawTabBadge({
        drawHost: "caribou",
        currentDrawId: currentCaribouDraw.id,
        myNumTicketsInDraw: currentCaribouDraw.myNumTicketsInDraw,
        drawBadgeViewData,
      });
    }

    return null;
  })();

  const socialPostTabBadge = ((): IconBadgeData | null => {
    if (!tabBadgeDataQuery.data) {
      return null; // still loading query
    }
    const { myUnreadSocialPostSummary } = tabBadgeDataQuery.data;

    return myUnreadSocialPostSummary.hasUnreadLatestPost
      ? {
          type: "unread_badge",
        }
      : null;
  })();

  // marks tabs badges as viewed when we navigate to the tab:
  useEffect(() => {
    switch (currentRoute) {
      case "my-rewards": {
        // mark current redeemed point balance as viewed when we go to rewards page
        const currentPointsRedeemed =
          tabBadgeDataQuery.data?.myRewardsUser.pointsRedeemed;
        const showingRewardsTabBadge = rewardTabBadge !== null;

        if (currentPointsRedeemed !== undefined && showingRewardsTabBadge) {
          setRewardBadgeViewData(() => ({
            lastViewedPointsRedeemed: currentPointsRedeemed,
          }));
        }
        break;
      }
      case "draw": {
        // mark current tickets as viewed when we go to the draw page
        const currentDraw = tabBadgeDataQuery.data?.currentCaribouSponsoredDraw;
        if (currentDraw) {
          setDrawBadgeViewData(() => ({
            lastViewedDrawId: currentDraw.id,
            lastViewedTickets: currentDraw.myNumTicketsInDraw,
          }));
        }
        break;
      }
      case "moments": {
        // mark social post as viewed when we go to the moments page
        if (socialPostTabBadge) {
          setSocialPostBadgeViewData(null);
        }
        break;
      }
    }
  }, [
    currentRoute,
    rewardTabBadge,
    setDrawBadgeViewData,
    setRewardBadgeViewData,
    tabBadgeDataQuery.data?.currentCaribouSponsoredDraw,
    tabBadgeDataQuery.data?.myRewardsUser.pointsRedeemed,
    socialPostTabBadge,
  ]);

  useEffect(() => {
    setSocialPostBadgeViewData(
      tabBadgeDataQuery.data?.myUnreadSocialPostSummary.hasUnreadLatestPost
        ? {
            type: "unread_badge",
          }
        : null
    );
  }, [tabBadgeDataQuery.data?.myUnreadSocialPostSummary.hasUnreadLatestPost]);

  return {
    rewardNotificationBadge: rewardTabBadge,
    drawNotificationBadge: drawTabBadge,
    socialPostNotificationBadge: socialPostBadgeViewData,
  };
}
