import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SharableJobPostingsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
  filters?: Types.Maybe<Types.ListShareableJobPostingsFilters>;
}>;


export type SharableJobPostingsQuery = (
  { __typename?: 'Query' }
  & { listShareableJobPostings?: Types.Maybe<(
    { __typename?: 'JobPostingListResponse' }
    & Pick<Types.JobPostingListResponse, 'total'>
    & { items: Array<(
      { __typename?: 'JobPosting' }
      & Pick<Types.JobPosting, 'id' | 'title' | 'ratesOfPay' | 'geography' | 'hoursRequired'>
      & { activePromotion?: Types.Maybe<(
        { __typename?: 'JobPostingPromotion' }
        & Pick<Types.JobPostingPromotion, 'id' | 'bannerMessage'>
      )>, branch?: Types.Maybe<(
        { __typename?: 'RewardsOrganizationBranch' }
        & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
      )>, organization: (
        { __typename?: 'RewardsOrganization' }
        & Pick<Types.RewardsOrganization, 'id' | 'branchName' | 'pointsPerDollar'>
      ), referralStructure: (
        { __typename?: 'ReferralStructure' }
        & Pick<Types.ReferralStructure, 'id' | 'totalAwardablePointValue'>
      ) }
    )> }
  )> }
);


export const SharableJobPostingsDocument = gql`
    query SharableJobPostings($limit: Int!, $offset: Int!, $filters: ListShareableJobPostingsFilters) {
  listShareableJobPostings(limit: $limit, offset: $offset, filters: $filters) {
    total
    items {
      id
      title
      ratesOfPay
      geography
      hoursRequired
      activePromotion {
        id
        bannerMessage
      }
      branch {
        id
        name
      }
      organization {
        id
        branchName
        pointsPerDollar
      }
      referralStructure {
        id
        totalAwardablePointValue
      }
    }
  }
}
    `;

/**
 * __useSharableJobPostingsQuery__
 *
 * To run a query within a React component, call `useSharableJobPostingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharableJobPostingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharableJobPostingsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSharableJobPostingsQuery(baseOptions: Apollo.QueryHookOptions<SharableJobPostingsQuery, SharableJobPostingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SharableJobPostingsQuery, SharableJobPostingsQueryVariables>(SharableJobPostingsDocument, options);
      }
export function useSharableJobPostingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SharableJobPostingsQuery, SharableJobPostingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SharableJobPostingsQuery, SharableJobPostingsQueryVariables>(SharableJobPostingsDocument, options);
        }
export type SharableJobPostingsQueryHookResult = ReturnType<typeof useSharableJobPostingsQuery>;
export type SharableJobPostingsLazyQueryHookResult = ReturnType<typeof useSharableJobPostingsLazyQuery>;
export type SharableJobPostingsQueryResult = Apollo.QueryResult<SharableJobPostingsQuery, SharableJobPostingsQueryVariables>;