import { compact, initial, last, times } from "lodash";

import { Alert } from "@rewards-web/shared/components/alert";
import { SocialPostCardSkeleton } from "@rewards-web/shared/components/social-post-card/social-post-card-skeleton";
import { usePathParamToggle } from "@rewards-web/shared/hooks/use-path-param-toggle";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { ErrorPage } from "@rewards-web/shared/pages/error";

import { useAuth } from "../../../shared/modules/auth";
import { PageContentContainer } from "../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../shared/scroll-to-top-on-mount";
import { SubPageHeader } from "../../../shared/sub-page-header";
import { useInfiniteScrollRef } from "./hooks";
import { useListSocialPostsQuery } from "./list-social-posts.generated";
import { SocialPostCard } from "./social-post-card";
import { useRecordSocialPostViewsMutation } from "./social-post-card/record-social-post-views.generated";
import { SocialPostModal } from "./social-post-modal";

const POSTS_PER_PAGE = 10;

export function SocialPage() {
  const track = useTrack();
  const { formatMessage } = useFormatters();
  const { getUserIsImpersonating } = useAuth();
  const [viewingPostId, { navigateToOffPath }] = usePathParamToggle({
    param: "id",
    onPath: "/moments/:id",
    offPath: "/moments",
  });

  const socialPostsQuery = useListSocialPostsQuery({
    variables: { limit: POSTS_PER_PAGE },
    fetchPolicy: "network-only",

    // important: we need to set this to cache-first, otherwise the
    // recorded reactions will cause posts to be re-fetched and create
    // UI glitchiness
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    onError: reportError,
  });

  // Intended to be used as a batched mutation to record views for multiple posts on screen
  const [recordViews] = useRecordSocialPostViewsMutation({
    onError: reportError,
  });

  const hasNextPage = Boolean(socialPostsQuery.data?.posts.nextCursor);

  const lastElementRef = useInfiniteScrollRef({
    hasNextPage,
    fetchNextPage: () =>
      socialPostsQuery.fetchMore({
        variables: { cursor: socialPostsQuery.data!.posts.nextCursor },
      }),
  });

  const content = (() => {
    if (socialPostsQuery.error) {
      return <ErrorPage />;
    }

    if (!socialPostsQuery.data) {
      // display skeleton state

      return times(3, (index) => (
        <SocialPostCardSkeleton key={`skeleton:${index}`} />
      ));
    }

    if (socialPostsQuery.data.posts.items.length === 0) {
      return (
        <Alert
          severity="info"
          message={formatMessage({
            defaultMessage: "There are no posts yet. Come back soon!",
            description: "Moments page > feed empty state message",
          })}
        />
      );
    }

    const initialPosts = initial(socialPostsQuery.data.posts.items);
    const lastPost = last(socialPostsQuery.data.posts.items);

    return compact([
      ...initialPosts.map((post, index) => {
        return (
          <SocialPostCard
            key={post.id}
            post={post}
            onCardOnScreen={() => {
              if (!post.viewed && !getUserIsImpersonating()) {
                // only record a post view if the post has not been viewed yet,
                // and the user is not impersonating
                try {
                  recordViews({
                    variables: {
                      postIds: [post.id],
                    },
                  });
                } catch (error) {
                  reportError(error);
                }
              }
              track("Viewed social post in feed", {
                postId: post.id,
                postIndexOnScreen: index,
                postType: post.type,
                postPublishedAt: post.publishedAt,
                existingReactions: post.reactionsSummary.reduce<
                  Record<string, number>
                >((acc, reaction) => {
                  acc[reaction.type] = reaction.numReactions;
                  return acc;
                }, {}),
                existingUserReactions: post.myRecordedReactionsSummary.reduce<
                  Record<string, number>
                >((acc, reaction) => {
                  acc[reaction.type] = reaction.numReactions;
                  return acc;
                }, {}),
              });
            }}
          />
        );
      }),

      lastPost && (
        // Wrap the last post in a div so we can use the ref to scroll to the top
        // when the user scrolls to the bottom of the page
        // (it's triggered if they view the last post, or the skeleton state)
        <div ref={lastElementRef} key={lastPost.id}>
          <SocialPostCard post={lastPost} />
          {/* Show skeleton state if there's a next page. */}
          {hasNextPage && <SocialPostCardSkeleton key="skeleton" />}
        </div>
      ),
    ]);
  })();

  return (
    <>
      <SubPageHeader
        pageName={formatMessage({
          defaultMessage: "Moments",
          description: "Moments page > page name",
        })}
        analyticsPageName="Moments"
      />
      <PageContentContainer>
        <ScrollToTopOnMount />
        {content}
      </PageContentContainer>

      <SocialPostModal
        open={!!viewingPostId}
        postId={viewingPostId}
        onClose={() => navigateToOffPath()}
      />
    </>
  );
}
