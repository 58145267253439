/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PointsIcon } from "../../../../../../../shared/points-icon";

export interface RedeemDrawerRequireEmailDisclaimerStepProps {
  pointsAvailableToRedeem: number;
  redemptionMethod:
    | RedemptionMethod.Catalog
    | RedemptionMethod.GenericGiftCardLink;
  onCancel: () => void;
  onEnterUpdateEmailFlow: () => void;
  setFooterHeight: (height: number) => void;
  footerType: "fixed" | "sticky";
}

export function RedeemDrawerRequireEmailDisclaimerStep({
  pointsAvailableToRedeem,
  redemptionMethod,
  onCancel,
  onEnterUpdateEmailFlow,
  setFooterHeight,
  footerType,
}: RedeemDrawerRequireEmailDisclaimerStepProps) {
  const { formatMessage } = useFormatters();

  const track = useTrack();

  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setFooterHeight(footerRef.current?.getBoundingClientRect().height || 0);
  }, [footerRef, setFooterHeight]);

  useEffect(() => {
    track("Viewed giftcard redemption flow - require email disclaimer step");
  }, [track]);

  return (
    <>
      <div
        css={(theme: AppTheme) =>
          css`
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 0 ${theme.spacing(2)};
          `
        }
      >
        <div
          css={(appTheme: AppTheme) => css`
            text-transform: uppercase;
            padding: ${appTheme.spacing(0.5)} ${appTheme.spacing(1.5)};
            border-radius: 500px;
            background-color: ${appTheme.palette.tertiary.main};
            display: flex;
            align-items: center;
            gap: ${appTheme.spacing(1.25)};
            margin-bottom: ${appTheme.spacing(2.5)};
          `}
        >
          <PointsIcon />
          <Typography variant="caption" color="white">
            {formatMessage(
              {
                defaultMessage: "{redeemable_points, number} points",
                description: "Redeem points drawer > number of points",
              },
              { redeemable_points: pointsAvailableToRedeem }
            )}
          </Typography>
        </div>
        <Typography variant="h5">
          <FormattedMessage
            description="Redeem points drawer > require email disclaimer > title"
            defaultMessage="We need your email"
          />
        </Typography>
        <Typography
          css={(theme: AppTheme) =>
            css`
              margin-top: ${theme.spacing(2.5)};
            `
          }
        >
          {redemptionMethod === RedemptionMethod.GenericGiftCardLink && (
            <FormattedMessage
              description="Redeem points drawer > require email disclaimer > title"
              defaultMessage="We couldn't find your email address in our records. Please update your email to receive your gift card."
            />
          )}
          {redemptionMethod === RedemptionMethod.Catalog && (
            <FormattedMessage
              description="Redeem points drawer > require email disclaimer > title gifts"
              defaultMessage="We couldn't find your email address in our records. Please update your email to redeem your gift."
            />
          )}
        </Typography>
      </div>
      <div
        ref={footerRef}
        css={(appTheme: AppTheme) => css`
          position: ${footerType};
          bottom: 0;
          width: 100%;
          padding: ${appTheme.spacing(2)};
          background-color: ${appTheme.palette.background.paper};
          display: flex;
          align-items: center;
          gap: ${appTheme.spacing(1.5)};
        `}
      >
        <Button
          label={formatMessage({
            defaultMessage: "Cancel",
            description: "Redeem points drawer > cancel button",
          })}
          onClick={() => {
            track(
              "Cancel pressed in giftcard redemption flow - require email disclaimer step"
            );
            onCancel();
          }}
          variant="outlined"
        />
        <Button
          onClick={() => {
            track(
              "Add email pressed in giftcard redemption flow - require email disclaimer step"
            );
            onEnterUpdateEmailFlow();
          }}
          color="secondary"
          label={formatMessage({
            defaultMessage: "Add email",
            description:
              "Redeem points drawer > require email disclaimer > add email button label",
          })}
        />
      </div>
    </>
  );
}
