/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { SocialPostCardSkeleton } from "@rewards-web/shared/components/social-post-card/social-post-card-skeleton";
import { useTrack } from "@rewards-web/shared/modules/analytics";

import { SocialPostCard } from "../social-post-card";
import { CloseIcon } from "./close-icon";
import { useSocialPostModalGetSocialPostByIdQuery } from "./get-social-post.generated";

interface SocialPostModalProps {
  open: boolean;
  postId: string | undefined;
  onClose: () => void;
}

export function SocialPostModal({
  open,
  postId,
  onClose,
}: SocialPostModalProps) {
  const track = useTrack();
  const theme = useTheme();
  const { data, error } = useSocialPostModalGetSocialPostByIdQuery({
    variables: { id: postId! },
    skip: !postId,
  });

  if (!postId) {
    return null;
  }

  const content = () => {
    if (error) {
      return (
        <Alert
          severity="error"
          message="Something went wrong while loading this post. Please try again later."
        />
      );
    }

    if (!data) {
      return <SocialPostCardSkeleton />;
    }

    // if the social post doesn't exist, show a different error?
    if (!data.getSocialPostById) {
      return (
        <Alert
          severity="error"
          message="Unexpected error. This post doesn't exist."
        />
      );
    }

    return <SocialPostCard post={data.getSocialPostById} alwaysExpanded />;
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={() => {
        track("Social post card show more collapsed", {
          postId: postId,
          postType: data?.getSocialPostById?.type,
        });
        onClose();
      }}
      maxWidth="1000px"
      backgroundColor="white"
      fullScreenOnMobile
    >
      <div
        css={css`
          padding: ${theme.spacing(4)};
          padding-bottom: 0;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          <IconButton onClick={onClose} aria-label="Close">
            <CloseIcon fill={theme.palette.primary.main} />
          </IconButton>
        </div>

        {content()}
      </div>
    </ResponsiveDialog>
  );
}
