/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { TripleStarAnimation } from "../../../../../shared/triple-star-animation";

export interface InsufficientPointsDrawerProps {
  open: boolean;
  onClose: () => void;
  onExited?: () => void;
  pointsAvailableToRedeem: number;
  minimumPointsToRedeem: number;
}

export function InsufficientPointsDrawer({
  open,
  onClose,
  onExited,
  pointsAvailableToRedeem,
  minimumPointsToRedeem,
}: InsufficientPointsDrawerProps) {
  const { formatMessage } = useFormatters();
  const theme = useTheme();

  return (
    <ResponsiveDialog
      title={formatMessage({
        defaultMessage: "You're almost there!",
        description: "Redeem points drawer > insufficient points > title",
      })}
      open={open}
      onClose={onClose}
      maxWidth="400px"
      onExited={onExited}
      backgroundColor={theme.palette.background.paper}
      paddingBottom={0}
    >
      <div
        css={(appTheme: AppTheme) =>
          css`
            padding: ${appTheme.spacing(2.5)};
            padding-top: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
          `
        }
      >
        <TripleStarAnimation />
        <Typography
          variant="subtitle"
          css={(appTheme: AppTheme) =>
            css`
              margin-top: ${appTheme.spacing(3.75)};
            `
          }
        >
          {formatMessage(
            {
              defaultMessage:
                "You need <bolded_text>{points, number} points</bolded_text> before you can redeem!",
              description:
                "Redeem points drawer > insufficient points > points required message",
            },
            {
              bolded_text: (nodes) => (
                <span
                  css={(appTheme: AppTheme) =>
                    css`
                      color: ${appTheme.palette.primary.main};
                    `
                  }
                >
                  {nodes}
                </span>
              ),
              points: minimumPointsToRedeem - pointsAvailableToRedeem,
            }
          )}
        </Typography>
        <Typography
          color="textPrimary"
          variant="footnote"
          css={(appTheme: AppTheme) =>
            css`
              margin-top: ${appTheme.spacing(1)};
            `
          }
        >
          {formatMessage({
            defaultMessage: "Find ways to earn points on your Home page.",
            description:
              "Redeem points drawer > insufficient points > ways to earn points",
          })}
        </Typography>
      </div>
    </ResponsiveDialog>
  );
}
