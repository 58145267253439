/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useEffect, useRef } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { usePointBalance } from "../../../../../../../shared/modules/point-balance";
import { PointsPill } from "../../../../../../../shared/points-pill";
import { RedemptionCatalogItemFragmentFragment } from "../../../../redeem/catalog-giftcard-redemption-info/catalog-item-list/redemption-catalog-item-fragment.generated";
import { useRedeemPointsMutation } from "../../redeem-points.generated";
import { useRedeemCatalogItemMutation } from "./redeem-catalog-item.generated";

export interface RedeemDrawerRedeemStepProps {
  pointsAvailableToRedeem: number;
  latestEmail: string;
  isLatestEmailValid: boolean;
  onCancel: () => void;
  onRedeemedPoints: () => void;
  onEnterUpdateEmailFlow: () => void;
  details:
    | {
        redemptionMethod: RedemptionMethod.GenericGiftCardLink;
      }
    | {
        redemptionMethod: RedemptionMethod.Catalog;
        catalogItem: RedemptionCatalogItemFragmentFragment;
      };
  setFooterHeight: (height: number) => void;
  footerType: "fixed" | "sticky";
}

export function RedeemDrawerRedeemStep({
  pointsAvailableToRedeem,
  latestEmail,
  isLatestEmailValid,
  onCancel,
  onRedeemedPoints,
  onEnterUpdateEmailFlow,
  details,
  setFooterHeight,
  footerType,
}: RedeemDrawerRedeemStepProps) {
  const { formatMessage } = useFormatters();

  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setFooterHeight(footerRef.current?.getBoundingClientRect().height || 0);
  }, [footerRef, setFooterHeight]);

  const pointBalance = usePointBalance({ refreshOnMount: true });
  const snackbar = useSnackbar();

  const track = useTrack();

  useEffect(() => {
    track("Viewed giftcard redemption flow - redeem step");
  }, [track]);

  const [
    redeemCatalogItem,
    { loading: redeemCatalogItemLoading },
  ] = useRedeemCatalogItemMutation();

  const [
    redeemPoints,
    { loading: redeemPointsLoading },
  ] = useRedeemPointsMutation();

  const onRedeem = async () => {
    try {
      if (details.redemptionMethod === RedemptionMethod.Catalog) {
        await redeemCatalogItem({
          variables: {
            items: [
              {
                catalogItemId: details.catalogItem.id,
                pointValue: details.catalogItem.priceInPoints,
              },
            ],
          },
        });
      } else {
        await redeemPoints({
          variables: {
            amount: pointBalance.computedBalance!,
            redemptionMethod: RedemptionMethod.GenericGiftCardLink,
          },
        });
      }

      onRedeemedPoints();
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: formatMessage({
          description: "Redeem points drawer > error",
          defaultMessage:
            "Something went wrong when redeeming your points. Please try again later.",
        }),
      });
    }
  };

  const borderedEmail = (node: ReactNode) => (
    <Typography
      variant="subtitle"
      css={(appTheme: AppTheme) => css`
        width: 100%;
        margin-top: ${appTheme.spacing(1.25)};
        padding: ${appTheme.spacing(2.5)};
        text-align: center;
        border-radius: 10px;
        background-color: ${appTheme.palette.grey[200]};
      `}
    >
      {node}
    </Typography>
  );

  return (
    <>
      <div
        css={(appTheme: AppTheme) => css`
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 0 ${appTheme.spacing(2)};
        `}
      >
        {details.redemptionMethod === RedemptionMethod.GenericGiftCardLink && (
          <PointsPill
            points={pointsAvailableToRedeem}
            css={(appTheme: AppTheme) => css`
              margin-bottom: ${appTheme.spacing(2.5)};
            `}
          />
        )}

        {isLatestEmailValid && (
          <>
            <div
              css={(appTheme: AppTheme) => css`
                width: 100%;
                margin-bottom: ${appTheme.spacing(1)};
              `}
            >
              <Typography variant="body">
                {details.redemptionMethod ===
                  RedemptionMethod.GenericGiftCardLink &&
                  formatMessage(
                    {
                      defaultMessage:
                        "A link to choose your gift card will be sent to <bordered_email>{email}</bordered_email>",
                      description: "Redeem points drawer > giftcard email note",
                    },
                    {
                      email: latestEmail,
                      bordered_email: borderedEmail,
                    }
                  )}

                {details.redemptionMethod === RedemptionMethod.Catalog && (
                  <Typography variant="body">
                    {formatMessage(
                      {
                        defaultMessage:
                          "A link to redeem your gift will be sent to <bordered_email>{email}</bordered_email>",
                        description: "Redeem points drawer > gift email note",
                      },
                      {
                        email: latestEmail,
                        bordered_email: borderedEmail,
                      }
                    )}
                  </Typography>
                )}
              </Typography>
            </div>
            <Typography
              variant="buttonLarge"
              color="primary"
              css={(appTheme: AppTheme) => css`
                cursor: pointer;
                text-decoration: none;
                padding: ${appTheme.spacing(1.5, 3)};
              `}
              onClick={() => {
                track(
                  "Change email pressed in giftcard redemption flow - redeem step"
                );
                onEnterUpdateEmailFlow();
              }}
            >
              <FontAwesomeIcon
                css={(appTheme: AppTheme) =>
                  css`
                    margin-right: ${appTheme.spacing(0.75)};
                  `
                }
                icon={faPenToSquare}
              />
              {formatMessage({
                description:
                  "Redeem points drawer > giftcard change email label",
                defaultMessage: "Change email",
              })}
            </Typography>
            {details.redemptionMethod === RedemptionMethod.Catalog && (
              <Typography variant="body" color="text.primary">
                {formatMessage({
                  defaultMessage:
                    "Redemption emails may take up to 2 business days to arrive. Applicable taxes will apply.",
                  description: "Redeem points drawer > gift email timing note",
                })}
              </Typography>
            )}
          </>
        )}
      </div>
      <div
        ref={footerRef}
        css={(appTheme: AppTheme) => css`
          position: ${footerType};
          bottom: 0;
          display: flex;
          align-items: center;
          gap: ${appTheme.spacing(1.5)};
          padding: ${appTheme.spacing(2)};
          background-color: ${appTheme.palette.background.paper};
          width: 100%;
        `}
      >
        <Button
          label={formatMessage({
            defaultMessage: "Cancel",
            description: "Redeem points drawer > cancel button",
          })}
          onClick={() => {
            track("Cancel pressed in giftcard redemption flow - redeem step");
            onCancel();
          }}
          variant="outlined"
        />
        <Button
          loading={redeemPointsLoading || redeemCatalogItemLoading}
          onClick={() => {
            track("Redeem pressed in giftcard redemption flow - redeem step");
            onRedeem();
          }}
          color="secondary"
          label={formatMessage({
            defaultMessage: "Redeem",
            description: "Redeem points drawer > redeem button",
          })}
        />
      </div>
    </>
  );
}
