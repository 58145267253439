import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type SocialPostCardFragmentFragment = (
  { __typename?: 'SocialPost' }
  & Pick<Types.SocialPost, 'id' | 'publishedAt' | 'type' | 'viewed'>
  & { taggedUsers: Array<(
    { __typename?: 'SocialPostTaggedUser' }
    & Pick<Types.SocialPostTaggedUser, 'id' | 'firstNameLastInitial'>
  )>, details?: Types.Maybe<(
    { __typename?: 'CustomSocialPostDetails' }
    & Pick<Types.CustomSocialPostDetails, 'id' | 'messageText'>
  ) | (
    { __typename?: 'BirthdaySocialPostDetails' }
    & Pick<Types.BirthdaySocialPostDetails, 'id' | 'imageVariant'>
    & { birthdayCopyVariant: Types.BirthdaySocialPostDetails['copyVariant'] }
  ) | (
    { __typename?: 'WorkMilestoneSocialPostDetails' }
    & Pick<Types.WorkMilestoneSocialPostDetails, 'id'>
    & { milestoneCopyVariant: Types.WorkMilestoneSocialPostDetails['copyVariant'] }
    & { milestone: (
      { __typename: 'WorkMilestoneSocialPostMilestoneVisit' }
      & Pick<Types.WorkMilestoneSocialPostMilestoneVisit, 'id' | 'visitNumber'>
    ) | (
      { __typename: 'WorkMilestoneSocialPostMilestoneHours' }
      & Pick<Types.WorkMilestoneSocialPostMilestoneHours, 'id' | 'numHours'>
    ) }
  ) | (
    { __typename?: 'RedemptionSocialPostDetails' }
    & Pick<Types.RedemptionSocialPostDetails, 'id' | 'numUsersRedeemed' | 'totalPointsRedeemed'>
    & { redemptionImageVariant: Types.RedemptionSocialPostDetails['imageVariant'], redemptionCopyVariant: Types.RedemptionSocialPostDetails['copyVariant'] }
  )>, reactionsSummary: Array<(
    { __typename?: 'ReactionsSummary' }
    & Pick<Types.ReactionsSummary, 'type' | 'numReactions'>
  )>, myRecordedReactionsSummary: Array<(
    { __typename?: 'ReactionsSummary' }
    & Pick<Types.ReactionsSummary, 'type' | 'numReactions'>
  )> }
);

export const SocialPostCardFragmentFragmentDoc = gql`
    fragment SocialPostCardFragment on SocialPost {
  id
  publishedAt
  type
  taggedUsers {
    id
    firstNameLastInitial
  }
  details {
    ... on CustomSocialPostDetails {
      id
      messageText
    }
    ... on BirthdaySocialPostDetails {
      id
      birthdayCopyVariant: copyVariant
      imageVariant
    }
    ... on WorkMilestoneSocialPostDetails {
      id
      milestone {
        __typename
        ... on WorkMilestoneSocialPostMilestoneVisit {
          id
          visitNumber
        }
        ... on WorkMilestoneSocialPostMilestoneHours {
          id
          numHours
        }
      }
      milestoneCopyVariant: copyVariant
    }
    ... on RedemptionSocialPostDetails {
      id
      redemptionImageVariant: imageVariant
      redemptionCopyVariant: copyVariant
      numUsersRedeemed
      totalPointsRedeemed
    }
  }
  reactionsSummary {
    type
    numReactions
  }
  myRecordedReactionsSummary {
    type
    numReactions
  }
  viewed
}
    `;