import * as Types from '@rewards-web/shared/graphql-types';

import { SocialPostCardFragmentFragment } from '../social-post-card/social-post-card-fragment.generated';
import { gql } from '@apollo/client';
import { SocialPostCardFragmentFragmentDoc } from '../social-post-card/social-post-card-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SocialPostModalGetSocialPostByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type SocialPostModalGetSocialPostByIdQuery = (
  { __typename?: 'Query' }
  & { getSocialPostById?: Types.Maybe<(
    { __typename?: 'SocialPost' }
    & SocialPostCardFragmentFragment
  )> }
);


export const SocialPostModalGetSocialPostByIdDocument = gql`
    query SocialPostModalGetSocialPostById($id: ID!) {
  getSocialPostById(id: $id) {
    ...SocialPostCardFragment
  }
}
    ${SocialPostCardFragmentFragmentDoc}`;

/**
 * __useSocialPostModalGetSocialPostByIdQuery__
 *
 * To run a query within a React component, call `useSocialPostModalGetSocialPostByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialPostModalGetSocialPostByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialPostModalGetSocialPostByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSocialPostModalGetSocialPostByIdQuery(baseOptions: Apollo.QueryHookOptions<SocialPostModalGetSocialPostByIdQuery, SocialPostModalGetSocialPostByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SocialPostModalGetSocialPostByIdQuery, SocialPostModalGetSocialPostByIdQueryVariables>(SocialPostModalGetSocialPostByIdDocument, options);
      }
export function useSocialPostModalGetSocialPostByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SocialPostModalGetSocialPostByIdQuery, SocialPostModalGetSocialPostByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SocialPostModalGetSocialPostByIdQuery, SocialPostModalGetSocialPostByIdQueryVariables>(SocialPostModalGetSocialPostByIdDocument, options);
        }
export type SocialPostModalGetSocialPostByIdQueryHookResult = ReturnType<typeof useSocialPostModalGetSocialPostByIdQuery>;
export type SocialPostModalGetSocialPostByIdLazyQueryHookResult = ReturnType<typeof useSocialPostModalGetSocialPostByIdLazyQuery>;
export type SocialPostModalGetSocialPostByIdQueryResult = Apollo.QueryResult<SocialPostModalGetSocialPostByIdQuery, SocialPostModalGetSocialPostByIdQueryVariables>;