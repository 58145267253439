import { forwardRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import { Alert } from "@rewards-web/shared/components/alert";
import { SocialPostCardSkeleton } from "@rewards-web/shared/components/social-post-card/social-post-card-skeleton";
import {
  CustomSocialPostDetails,
  ReactionType,
  SocialPostType,
} from "@rewards-web/shared/graphql-types";
import { useOnScreenV2 } from "@rewards-web/shared/hooks/use-on-screen";
import { shouldBeNever } from "@rewards-web/shared/lib/should-be-never";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { useCommonBaseSocialPostCardProps } from "../hooks";
import {
  BirthdaySocialPostCard,
  BirthdaySocialPostCardProps,
} from "./cards-by-type/birthday-social-post-card";
import { CustomSocialPostCard } from "./cards-by-type/custom-social-post-card";
import {
  RedemptionSocialPostCard,
  RedemptionSocialPostCardProps,
} from "./cards-by-type/redemption-social-post-card";
import { WelcomeSocialPostCard } from "./cards-by-type/welcome-social-post-card";
import {
  WorkMilestoneSocialPostCard,
  WorkMilestoneSocialPostCardProps,
} from "./cards-by-type/work-milestone-social-post-card";
import { useRecordSocialPostReactionMutation } from "./record-social-post-reaction.generated";
import { useRemoveSocialPostReactionMutation } from "./remove-social-post-reaction.generated";
import { useSocialPostCardDataQuery } from "./social-post-card-data.generated";
import { SocialPostCardFragmentFragment } from "./social-post-card-fragment.generated";

export interface SocialPostCardProps {
  post: SocialPostCardFragmentFragment;
  alwaysExpanded?: boolean;
  onCardOnScreen?(): void;
}

const DEBOUNCE_INTERVAL = 2_000;

export const SocialPostCard = forwardRef<Element, SocialPostCardProps>(
  ({ post, alwaysExpanded, onCardOnScreen }: SocialPostCardProps, refProp) => {
    const { formatMessage, formatDate } = useFormatters();
    const track = useTrack();
    const navigate = useNavigate();
    const [onScreen, onScreenRef] = useOnScreenV2({ threshold: 0.3 });

    const ref: (instance: Element | null) => void = (ref) => {
      // populate parent ref
      if (refProp) {
        if (typeof refProp === "function") {
          refProp(ref);
        } else {
          (refProp as React.MutableRefObject<Element | null>).current = ref;
        }
      }

      // also populate onScreen ref
      onScreenRef(ref);
    };

    useEffect(() => {
      if (onScreen) {
        onCardOnScreen?.();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onScreen]);

    const query = useSocialPostCardDataQuery({
      onError: reportError,
      fetchPolicy: "cache-first",
    });

    const date = (() => {
      const date = getRelativeDateType(post.publishedAt);

      switch (date) {
        case "today":
          return formatMessage({
            description: "Social post card > date > today",
            defaultMessage: "Today",
          });
        case "yesterday":
          return formatMessage({
            description: "Social post card > date > yesterday",
            defaultMessage: "Yesterday",
          });
        default:
          // Fallback to `formatDate` for other dates
          return formatDate(post.publishedAt, {
            dateStyle: "full",
          });
      }
    })();

    const [recordReaction] = useRecordSocialPostReactionMutation();
    const [removeReaction] = useRemoveSocialPostReactionMutation();

    const makeRecordOrRemoveReaction = (reactionType: ReactionType) => (
      added: boolean
    ) => {
      added
        ? removeReaction({
            variables: {
              postId: post.id,
              reactionType: reactionType,
            },
          })
        : recordReaction({
            variables: {
              postId: post.id,
              reactionType: reactionType,
            },
          });
    };

    const updateApplaudReaction = useDebouncedCallback(
      makeRecordOrRemoveReaction(ReactionType.Applaud),
      DEBOUNCE_INTERVAL,
      { leading: true }
    );
    const updateCelebrateReaction = useDebouncedCallback(
      makeRecordOrRemoveReaction(ReactionType.Celebrate),
      DEBOUNCE_INTERVAL,
      { leading: true }
    );
    const updateLoveReaction = useDebouncedCallback(
      makeRecordOrRemoveReaction(ReactionType.Love),
      DEBOUNCE_INTERVAL,
      { leading: true }
    );

    // When the component goes to be unmounted, we will record/remove any pending reactions
    useEffect(
      () => () => {
        for (const reaction of Object.values(ReactionType)) {
          switch (reaction) {
            case ReactionType.Applaud:
              updateApplaudReaction.flush();
              break;
            case ReactionType.Celebrate:
              updateCelebrateReaction.flush();
              break;
            case ReactionType.Love:
              updateLoveReaction.flush();
              break;
            default:
              shouldBeNever(reaction);
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const onMyReactionChange = (reactionType: ReactionType, added: boolean) => {
      track("reaction button clicked", {
        postId: post.id,
        reactionType,
        status: added ? "added" : "removed",
      });

      switch (reactionType) {
        case ReactionType.Applaud:
          updateApplaudReaction(added);
          break;
        case ReactionType.Celebrate:
          updateCelebrateReaction(added);
          break;
        case ReactionType.Love:
          updateLoveReaction(added);
          break;
        default:
          shouldBeNever(reactionType);
      }
    };

    const time = formatDate(post.publishedAt, {
      timeStyle: "short",
      dateStyle: undefined,
    });

    const { showMoreText } = useCommonBaseSocialPostCardProps();

    if (query.error) {
      return (
        <Alert
          severity="error"
          message={formatMessage({
            description: "Social post card > error",
            defaultMessage: "An unexpected error occurred loading this post.",
          })}
        />
      );
    }

    if (!query.data) {
      return <SocialPostCardSkeleton ref={ref} />;
    }

    const commonData = {
      logoUrl: query.data.getMyRewardsOrganization.logoImageUrl ?? null,
      publishedByText: query.data.getMyRewardsOrganization.shortName,
      publishedAtText: `${date} at ${time}`,
      postReactions: post.reactionsSummary,
      myReactions: post.myRecordedReactionsSummary,
      onMyReactionChange,
      alwaysExpanded,
      showMoreText,
      onShowMoreExpanded: () => {
        track("Social post card show more expanded", {
          postId: post.id,
          postType: post.type,
        });
        navigate(`/moments/${post.id}`);
      },
    };

    switch (post.type) {
      case SocialPostType.Custom:
        return (
          <CustomSocialPostCard
            ref={ref}
            commonData={commonData}
            details={post.details as CustomSocialPostDetails}
          />
        );

      case SocialPostType.Birthday: {
        return (
          <BirthdaySocialPostCard
            ref={ref}
            commonData={commonData}
            details={post.details as BirthdaySocialPostCardProps["details"]}
            taggedUsers={post.taggedUsers}
          />
        );
      }

      case SocialPostType.WorkMilestone: {
        return (
          <WorkMilestoneSocialPostCard
            ref={ref}
            commonData={commonData}
            postId={post.id}
            details={
              post.details as WorkMilestoneSocialPostCardProps["details"]
            }
            taggedUsers={post.taggedUsers}
          />
        );
      }
      case SocialPostType.Redemption: {
        return (
          <RedemptionSocialPostCard
            ref={ref}
            commonData={commonData}
            details={post.details as RedemptionSocialPostCardProps["details"]}
            taggedUsers={post.taggedUsers}
          />
        );
      }

      case SocialPostType.Welcome: {
        return <WelcomeSocialPostCard ref={ref} commonData={commonData} />;
      }

      default:
        // don't render posts we can't recognize
        return null;
    }
  }
);

function getRelativeDateType(date: Date): "today" | "yesterday" | "other" {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // Remove time components for accurate comparison
  const dateOnly = new Date(new Date(date).toDateString());
  const todayOnly = new Date(today.toDateString());
  const yesterdayOnly = new Date(yesterday.toDateString());

  if (dateOnly.getTime() === todayOnly.getTime()) {
    return "today";
  } else if (dateOnly.getTime() === yesterdayOnly.getTime()) {
    return "yesterday";
  } else {
    // Fallback to `formatDate` for other dates
    return "other";
  }
}
