import { forwardRef, ReactNode } from "react";

import { BaseSocialPostCard } from "@rewards-web/shared/components/social-post-card/base-social-post-card";
import {
  ReactionsSummary,
  ReactionType,
  RedemptionSocialPostCopyVariant,
  RedemptionSocialPostDetails,
  RedemptionSocialPostImageVariant,
} from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { SocialPostCardFragmentFragment } from "../../social-post-card-fragment.generated";
import redemptionCoinsImageUrl from "./images/coins.png";
import redemptionFireworksImageUrl from "./images/fireworks.png";
import redemptionGiftBoxImageUrl from "./images/gift-box.png";
import redemptionPartyHatImageUrl from "./images/party-hat.png";

export interface RedemptionSocialPostCardProps {
  commonData: {
    logoUrl: string | null;
    publishedByText: string;
    publishedAtText: string;
    postReactions: ReactionsSummary[];
    myReactions: ReactionsSummary[];
    onMyReactionChange: (reactionType: ReactionType, added: boolean) => void;
    showMoreText: string;
    alwaysExpanded?: boolean;
  };
  details: {
    __typename?: "RedemptionSocialPostDetails";
  } & Pick<RedemptionSocialPostDetails, "id"> & {
      redemptionImageVariant: RedemptionSocialPostDetails["imageVariant"];
      redemptionCopyVariant: RedemptionSocialPostDetails["copyVariant"];
      numUsersRedeemed: number;
      totalPointsRedeemed: number;
    };
  taggedUsers: SocialPostCardFragmentFragment["taggedUsers"];
}

export const RedemptionSocialPostCard = forwardRef(
  (
    { commonData, details, taggedUsers }: RedemptionSocialPostCardProps,
    ref
  ) => {
    const { formatMessage } = useFormatters();

    const overlayText = formatMessage(
      {
        description: "Social post card > redemption post > image overlay text",
        defaultMessage:
          "{total_points_redeemed, number} {total_points_redeemed, plural, one {point} other {points}}",
      },
      {
        total_points_redeemed: details.totalPointsRedeemed,
      }
    );

    const messageText = (() => {
      const bold = (nodes: ReactNode[]) => <strong>{nodes}</strong>;
      const br = () => <br />;

      switch (details.redemptionCopyVariant) {
        case RedemptionSocialPostCopyVariant.MultipleCaregiversCheers:
          return formatMessage(
            {
              description:
                "Social post card > redemption post > copy variant > multiple caregivers cheers",
              defaultMessage:
                "🎉 Cheers to our <bold>{num_users_redeemed, number} {num_users_redeemed, plural, one {caregiver} other {caregivers}} for redeeming {total_points_redeemed, number} {total_points_redeemed, plural, one {point} other {points}}</bold> - your well-deserved rewards are ready to go!",
            },
            {
              num_users_redeemed: details.numUsersRedeemed,
              total_points_redeemed: details.totalPointsRedeemed,
              bold,
            }
          );

        case RedemptionSocialPostCopyVariant.MultipleCaregiversAmazingWork:
          return (
            <>
              {formatMessage(
                {
                  description:
                    "Social post card > redemption post > copy variant > multiple caregivers amazing",
                  defaultMessage:
                    "<bold>Amazing work!</bold> 👏<br></br>Recently, {num_users_redeemed, number} team members redeemed a grand total of {total_points_redeemed, number} {total_points_redeemed, plural, one {point} other {points}}. Here's to each of you for your dedication!",
                },
                {
                  num_users_redeemed: details.numUsersRedeemed,
                  total_points_redeemed: details.totalPointsRedeemed,
                  bold,
                  br,
                }
              )}
            </>
          );

        case RedemptionSocialPostCopyVariant.MultipleCaregiversShoutOut:
          return (
            <>
              {formatMessage(
                {
                  description:
                    "Social post card > redemption post > copy variant > multiple caregivers shout out",
                  defaultMessage:
                    "<bold>Shoutout to Our Achievers!</bold> 🌟 <br></br>Lately, {num_users_redeemed, number} team members have collectively redeemed {total_points_redeemed, number} {total_points_redeemed, plural, one {point} other {points}}. Cheers to your hard work paying off!",
                },
                {
                  num_users_redeemed: details.numUsersRedeemed,
                  total_points_redeemed: details.totalPointsRedeemed,
                  bold,
                  br,
                }
              )}
            </>
          );

        case RedemptionSocialPostCopyVariant.MultipleCaregiversGreatWork:
          return (
            <>
              {formatMessage(
                {
                  description:
                    "Social post card > redemption post > copy variant > multiple caregivers great work",
                  defaultMessage:
                    "<bold>Great Work!</bold> 🎉 Lately, {num_users_redeemed, number} team members redeemed a total of {total_points_redeemed, number} {total_points_redeemed, plural, one {point} other {points}} — enjoy the rewards you've worked so hard for!",
                },
                {
                  num_users_redeemed: details.numUsersRedeemed,
                  total_points_redeemed: details.totalPointsRedeemed,
                  bold,
                }
              )}
            </>
          );

        case RedemptionSocialPostCopyVariant.SingleCaregiverGreatWork:
          return (
            <>
              {formatMessage(
                {
                  description:
                    "Social post card > redemption post > copy variant > single caregiver great work",
                  defaultMessage:
                    "<bold>Great Work!</bold> 🎉 Recently, {tagged_user_name} turned their hard-earned points into rewards — enjoy the perks of your effort! ",
                },
                {
                  tagged_user_name: taggedUsers[0]!.firstNameLastInitial,
                  bold,
                }
              )}
            </>
          );

        case RedemptionSocialPostCopyVariant.SingleCaregiverCheers:
          return (
            <>
              {formatMessage(
                {
                  description:
                    "Social post card > redemption post > copy variant > single caregiver cheers",
                  defaultMessage:
                    "🎉 Cheers to {tagged_user_name} for redeeming points — your well-deserved rewards are ready to go!",
                },
                {
                  tagged_user_name: taggedUsers[0]!.firstNameLastInitial,
                  bold,
                }
              )}
            </>
          );

        default:
          // unrecognized milestone copy variant - don't show message
          return null;
      }
    })();

    return (
      <BaseSocialPostCard
        ref={ref}
        data={{
          ...commonData,
          tagText: formatMessage({
            description: "Social post card > redemption post > tag",
            defaultMessage: "Redemption",
          }),
          tagColor: "gold",
          messageText,
          image: (() => {
            const shouldShowOverlayText = details.numUsersRedeemed > 1;

            switch (details.redemptionImageVariant) {
              case RedemptionSocialPostImageVariant.GiftBox:
                return {
                  url: redemptionGiftBoxImageUrl,
                  overlayText: shouldShowOverlayText
                    ? {
                        text: overlayText,
                        position: "top",
                        textColor: "#EA2276",
                        imageTintColor: "#E6ABC9",
                      }
                    : undefined,
                };
              case RedemptionSocialPostImageVariant.Coins:
                return {
                  url: redemptionCoinsImageUrl,
                  overlayText: shouldShowOverlayText
                    ? {
                        text: overlayText,
                        position: "right",
                        textColor: "#EA2276",
                        imageTintColor: "transparent",
                      }
                    : undefined,
                };
              case RedemptionSocialPostImageVariant.Fireworks:
                return {
                  url: redemptionFireworksImageUrl,
                  overlayText: shouldShowOverlayText
                    ? {
                        text: overlayText,
                        position: "center",
                        imageTintColor: "#4C3970",
                      }
                    : undefined,
                };
              case RedemptionSocialPostImageVariant.PartyHat:
                return {
                  url: redemptionPartyHatImageUrl,
                  overlayText: shouldShowOverlayText
                    ? {
                        text: overlayText,
                        position: "center",
                        imageTintColor: "#4C3970",
                      }
                    : undefined,
                };

              default:
                // fall back to no image if we don't recognize it in the frontend
                return null;
            }
          })(),
        }}
      />
    );
  }
);
