/** @jsxImportSource @emotion/react */
import { ApolloError } from "@apollo/client";
import { css } from "@emotion/react";
import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "@rewards-web/shared/components/typography";
import {
  RedemptionMethod,
  RewardsUserRedemptionStatus,
} from "@rewards-web/shared/graphql-types";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PointsIcon } from "../../../../../shared/points-icon";
import { PastRedemptionsDataQuery } from "../query.generated";
import { useResendGenericGiftCardLinkMutation } from "../resend-generic-gift-card-link.generated";
import { ResendGiftCardLinkDrawerVariantType } from "../resend-gift-card-link-drawer";

export interface RedemptionCardProps {
  redemption: PastRedemptionsDataQuery["getMyRewardsUserRedemptions"]["items"][0];
  setIsResendGiftCardLinkDrawerOpen(value: boolean): void;
  resendGiftCardTimer: number;
  setResendGiftCardTimer(value: number): void;
  setResendGiftCardLinkDrawerVariant(
    value: ResendGiftCardLinkDrawerVariantType
  ): void;
  email?: string | null;
}

export function RedemptionCard({
  redemption,
  setIsResendGiftCardLinkDrawerOpen,
  resendGiftCardTimer,
  setResendGiftCardTimer,
  setResendGiftCardLinkDrawerVariant,
  email,
}: RedemptionCardProps) {
  const { formatMessage, formatDate } = useFormatters();
  const snackbar = useSnackbar();
  const hasMissingEmail = !email || email.endsWith("@example.com");
  const resendGiftCardLinkEnabled = useFeatureFlag(
    "rewards-app-resend-gift-card-link-enabled-temp"
  );

  const [resendGenericGiftCardLink] = useResendGenericGiftCardLinkMutation({
    onCompleted: () => {
      setResendGiftCardLinkDrawerVariant("resent");
    },
    onError: (error) => {
      setIsResendGiftCardLinkDrawerOpen(false);
      reportError(error);

      if (
        error instanceof ApolloError &&
        error.graphQLErrors[0]?.message ===
          "Couldn't resend gift card link to existing email address"
      ) {
        snackbar.show({
          severity: "error",
          message: formatMessage(
            {
              description:
                "Resend gift card link > email in tango block list error > text",
              defaultMessage:
                "Couldn't resend gift card link to existing email address. Please email <a>help@caribou.care</a> with your email, full name and phone number.",
            },
            {
              a: (node) => (
                <a href={`mailto:${encodeURI("help@caribou.care")}`}>{node}</a>
              ),
            }
          ),
        });
      } else {
        snackbar.show({
          severity: "error",
          message: formatMessage({
            description: "Resend gift card link request > error",
            defaultMessage:
              "Something went wrong when resending your gift card link. Please try again later.",
          }),
        });
      }
    },
  });

  const pastRedemptionLabel = (() => {
    switch (redemption.redemptionMethod) {
      case RedemptionMethod.Hsa:
        return formatMessage({
          defaultMessage: "HSA",
          description: "Past redemptions > hsa label",
        });
      case RedemptionMethod.Payroll:
        return formatMessage({
          defaultMessage: "Paycheck",
          description: "Past redemptions > paycheck label",
        });
      case RedemptionMethod.GenericGiftCardLink:
        return formatMessage({
          defaultMessage: "Giftcard",
          description: "Past redemptions > giftcard label",
        });
      case RedemptionMethod.Catalog:
        const redeemedItems = redemption.redeemedItems.map(
          (item) => item.catalogItem.displayName
        );

        if (redeemedItems.length > 0) {
          return redeemedItems.join(", ");
        } else {
          return formatMessage({
            defaultMessage: "Gift",
            description: "Past redemptions > gift label",
          });
        }
      default:
        return formatMessage({
          defaultMessage: "Redemption",
          description: "Past redemptions > fallback label",
        });
    }
  })();

  return (
    <div
      css={(appTheme: AppTheme) => css`
        padding: ${appTheme.spacing(1.5)} ${appTheme.spacing(3)};
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        margin-bottom: ${appTheme.spacing(1.5)};
        border: 2px solid ${appTheme.palette.grey[200]};
        align-items: center;
      `}
    >
      <div>
        <Typography variant="footnote" color="textSecondary">
          {formatDate(redemption.requestedAt, {
            month: "long",
            day: "numeric",
          })}
        </Typography>
        <Typography color="textPrimary" variant="subtitle">
          {pastRedemptionLabel}
        </Typography>
        <div
          css={(appTheme: AppTheme) => css`
            display: flex;
            margin-top: ${appTheme.spacing(0.25)};
            align-items: center;
          `}
        >
          <PointsIcon />
          <Typography
            css={(appTheme: AppTheme) =>
              css`
                margin-left: ${appTheme.spacing(0.5)};
              `
            }
            variant="footnote"
            color="textSecondary"
          >
            {formatMessage(
              {
                defaultMessage: "{points, number} points redeemed",
                description: "Past redemptions > points redeemed label",
              },
              { points: redemption.points }
            )}
          </Typography>
        </div>

        {resendGiftCardLinkEnabled &&
          redemption.redemptionMethod ===
            RedemptionMethod.GenericGiftCardLink &&
          redemption.status === RewardsUserRedemptionStatus.Fulfilled && (
            <div
              css={css`
                position: relative;
              `}
            >
              <Typography
                variant="footnote"
                color="primary"
                css={(appTheme: AppTheme) =>
                  css`
                    margin-top: ${appTheme.spacing(0.5)};
                    cursor: pointer;
                  `
                }
                onClick={() => {
                  if (hasMissingEmail) {
                    setIsResendGiftCardLinkDrawerOpen(true);
                  } else {
                    if (resendGiftCardTimer > 0) {
                      setResendGiftCardLinkDrawerVariant(
                        "must_wait_before_retry"
                      );
                    }

                    if (resendGiftCardTimer === 0) {
                      setResendGiftCardLinkDrawerVariant("resend_in_flight");
                      resendGenericGiftCardLink({
                        variables: {
                          redemptionId: redemption.id,
                        },
                      });

                      /**
                       * Note: Tango API for resending order has a limitation of calling the API once every 30 seconds.
                       * Ref: https://developers.tangocard.com/docs/manage-orders#resend-a-specific-order:~:text=Orders%20can%20be%20resent%20once%20every%2030%20seconds.
                       * This limitation is mentioned for resending the order, but is not mentioned for the docs for reseinding a line item.
                       * Ref: https://developers.tangocard.com/docs/manage-line-items#resend-a-specific-line-item
                       */
                      setResendGiftCardTimer(30);
                    }

                    setIsResendGiftCardLinkDrawerOpen(true);
                  }
                }}
              >
                <FontAwesomeIcon
                  css={(appTheme: AppTheme) =>
                    css`
                      margin-right: ${appTheme.spacing(0.5)};
                    `
                  }
                  icon={faPaperPlane}
                />
                {formatMessage({
                  defaultMessage: "Resend gift card link",
                  description:
                    "Past redemptions > resendgift card link > button label",
                })}
              </Typography>
            </div>
          )}
      </div>
      <Typography variant="h6" color="primary">
        {formatDollars(redemption.dollars)}
      </Typography>
    </div>
  );
}
