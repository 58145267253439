import { useApolloClient } from "@apollo/client";
import { noop } from "lodash";
import { createContext, ReactNode, useContext, useState } from "react";

import { useRecordCheckIn } from "../check-in";

export const selectedTenant: { id: string | null } = { id: null };

interface SelectedTenantProviderProps {
  children: ReactNode;
}

const SelectedTenantContext = createContext<{
  selectedTenantId: string | null;
  loading: boolean;
  setSelectedTenantId(tenantId: string | null): void;
}>({ selectedTenantId: null, loading: false, setSelectedTenantId: noop });

export function SelectedTenantProvider({
  children,
}: SelectedTenantProviderProps) {
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [selectedTenantId, setSelectedTenantId] = useState<string | null>(null);
  const recordCheckIn = useRecordCheckIn();

  const handleChange = async (tenantId: string | null) => {
    setLoading(true);
    selectedTenant.id = tenantId;
    setSelectedTenantId(tenantId);
    /**
     * Note: Calling recordCheckIn() here would not be needed once the known issue around switching tenants is fixed.
     * packages/rewards-app/src/shared/modules/auth/index.tsx
     */
    await recordCheckIn();
    await apolloClient.resetStore();
    setLoading(false);
  };

  return (
    <SelectedTenantContext.Provider
      value={{
        selectedTenantId: selectedTenantId,
        setSelectedTenantId: handleChange,
        loading,
      }}
    >
      {children}
    </SelectedTenantContext.Provider>
  );
}

export function useSelectedTenantId(): {
  selectedTenantId: string | null;
  loading: boolean;
  setSelectedTenantId: (tenantId: string | null) => void;
} {
  const { selectedTenantId, loading, setSelectedTenantId } = useContext(
    SelectedTenantContext
  );
  return {
    selectedTenantId,
    loading,
    setSelectedTenantId,
  };
}
