import { forwardRef } from "react";

import { BaseSocialPostCard } from "@rewards-web/shared/components/social-post-card/base-social-post-card";
import {
  ReactionsSummary,
  ReactionType,
} from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

interface WelcomeSocialPostCardProps {
  commonData: {
    logoUrl: string | null;
    publishedByText: string;
    publishedAtText: string;
    postReactions: ReactionsSummary[];
    myReactions: ReactionsSummary[];
    onMyReactionChange: (reactionType: ReactionType, added: boolean) => void;
    showMoreText: string;
    alwaysExpanded?: boolean;
  };
}

export const WelcomeSocialPostCard = forwardRef(
  ({ commonData }: WelcomeSocialPostCardProps, ref) => {
    const { formatMessage } = useFormatters();

    return (
      <BaseSocialPostCard
        ref={ref}
        data={{
          ...commonData,
          tagText: formatMessage({
            description: "Social post card > welcome post > tag",
            defaultMessage: "Announcement",
          }),
          tagColor: "primary",
          messageText: formatMessage({
            description: "Social post card > welcome post > message",
            defaultMessage:
              "Welcome to Moments! 🎉 This is your space to celebrate milestones and connect with your team! Stay tuned for more posts.",
          }),
          image: null, // no image supported for now
        }}
      />
    );
  }
);
