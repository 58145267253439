/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { useEffect, useRef } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import EnvelopeUrl from "../../../../images/email-icon.png";

export interface RedeemDrawerRedeemSuccessProps {
  redemptionMethod:
    | RedemptionMethod.Catalog
    | RedemptionMethod.GenericGiftCardLink;
  onClose: () => void;
  email: string;
  setFooterHeight: (height: number) => void;
  footerType: "fixed" | "sticky";
}

export function RedeemDrawerRedeemSuccess({
  redemptionMethod,
  onClose,
  email,
  setFooterHeight,
  footerType,
}: RedeemDrawerRedeemSuccessProps) {
  const { formatMessage } = useFormatters();
  const smallScreen = useMediaQuery("(max-width: 400px)");

  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setFooterHeight(footerRef.current?.getBoundingClientRect().height || 0);
  }, [footerRef, setFooterHeight]);

  const track = useTrack();

  useEffect(() => {
    track("Viewed giftcard redemption flow - redeem success step");
  }, [track]);

  return (
    <>
      <div
        css={(theme: AppTheme) =>
          css`
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 0 ${theme.spacing(2)};
          `
        }
      >
        <img
          src={EnvelopeUrl}
          width={smallScreen ? 96 : 135}
          alt={formatMessage({
            description:
              "Redeem points drawer > redeem succes > envelope icon alt",
            defaultMessage: "Email icon",
          })}
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(smallScreen ? 2 : 2.5)};
              ${smallScreen &&
              css`
                margin-top: ${theme.spacing(-2)};
              `}
            `
          }
        />
        {redemptionMethod === RedemptionMethod.Catalog && (
          <Typography variant="body">
            {formatMessage({
              defaultMessage:
                "Look out for an email in 48 hours (including spam/junk) to share your shipping info and complete your redemption.",
              description:
                "Redeem points drawer > redeem success > content gift",
            })}
          </Typography>
        )}
        {redemptionMethod === RedemptionMethod.GenericGiftCardLink && (
          <Typography variant="body">
            {formatMessage(
              {
                defaultMessage:
                  "A link to choose your gift card will be emailed to you at <bordered_email>{email}</bordered_email>This email may take up to 3 days to arrive, don't forget to check your Spam and Junk email folders.",
                description: "Redeem points drawer > redeem success > content",
              },
              {
                email,
                bordered_email: (nodes) => (
                  <Typography
                    variant="subtitle"
                    css={(theme: AppTheme) => css`
                      width: 100%;
                      margin: ${theme.spacing(2.5, 0)};
                      padding: ${theme.spacing(2.5)};
                      text-align: center;
                      border-radius: 10px;
                      background-color: ${theme.palette.grey[200]};
                    `}
                  >
                    <span
                      css={css`
                        overflow-wrap: break-word;
                      `}
                    >
                      {nodes}
                    </span>
                  </Typography>
                ),
              }
            )}
          </Typography>
        )}
      </div>
      <div
        ref={footerRef}
        css={(theme: AppTheme) => css`
          position: ${footerType};
          bottom: 0;
          width: 100%;
          padding: ${theme.spacing(2)};
          background-color: ${theme.palette.background.paper};
        `}
      >
        <Button
          type="button"
          onClick={() => {
            track(
              "Close pressed in giftcard redemption flow - redeem success step"
            );
            onClose();
          }}
          label={formatMessage({
            description:
              "Redeem points drawer > redeem success > close button label",
            defaultMessage: "Close",
          })}
          color="primary"
          width="full"
          size="large"
        />
      </div>
    </>
  );
}
