/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { ErrorPage } from "@rewards-web/shared/pages/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../shared/page-content-container";
import { PointsPill } from "../../../shared/points-pill";
import { ScrollToTopOnMount } from "../../../shared/scroll-to-top-on-mount";
import { SubPageHeader } from "../../../shared/sub-page-header";
import { useRedemptionPageData } from "../points-summary/hooks";
import { CatalogItemList } from "../points-summary/redeem/catalog-giftcard-redemption-info/catalog-item-list";
import { useRedemptionCatalogItemOptionsQuery } from "../points-summary/redeem/catalog-giftcard-redemption-info/catalog-item-list/redemption-catalog-item-options.generated";

export function RedeemGiftsPage() {
  const { formatMessage } = useFormatters();
  const pageDataQuery = useRedemptionPageData();

  const catalogItemsQuery = useRedemptionCatalogItemOptionsQuery({
    onError: reportError,
  });

  const content = (() => {
    if (pageDataQuery.error || catalogItemsQuery.error) {
      return <ErrorPage />;
    }

    if (!pageDataQuery.data || !catalogItemsQuery.data) {
      return <PageLoadingState />;
    }

    return (
      <>
        <Typography
          variant="footnote"
          color="grey.800"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          {formatMessage({
            defaultMessage: "Your points balance",
            description: "Redeem gifts page > points balance text",
          })}
        </Typography>

        <PointsPill
          points={pageDataQuery.data.pointsAvailableToRedeem}
          color="primary"
          css={(appTheme: AppTheme) => css`
            margin-bottom: ${appTheme.spacing(1)};
          `}
        />

        <CatalogItemList
          pointsAvailableToRedeem={pageDataQuery.data.pointsAvailableToRedeem}
          pointsPerDollar={pageDataQuery.data.pointsPerDollar}
          email={pageDataQuery.data.email}
          phoneNumber={pageDataQuery.data.phoneNumber}
          minimumPointsToRedeem={pageDataQuery.data.minimumPointsToRedeem}
          catalogItems={
            catalogItemsQuery.data?.getMyAvailableRedemptionCatalogItems ?? []
          }
        />
      </>
    );
  })();

  return (
    <div
      css={(theme: AppTheme) => css`
        height: 100%;
        overflow-y: scroll;
        background-color: white;
        padding-bottom: ${theme.spacing(2)};
      `}
    >
      <SubPageHeader
        analyticsPageName="Redeem gifts"
        backTo="rewards"
        color="white"
      />

      <PageContentContainer>
        <ScrollToTopOnMount />
        {content}
      </PageContentContainer>
    </div>
  );
}
