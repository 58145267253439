import { forwardRef } from "react";

import { BaseSocialPostCard } from "@rewards-web/shared/components/social-post-card/base-social-post-card";
import {
  CustomSocialPostDetails,
  ReactionsSummary,
  ReactionType,
} from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

interface CustomSocialPostCardProps {
  commonData: {
    logoUrl: string | null;
    publishedByText: string;
    publishedAtText: string;
    postReactions: ReactionsSummary[];
    myReactions: ReactionsSummary[];
    onMyReactionChange: (reactionType: ReactionType, added: boolean) => void;
    showMoreText: string;
    alwaysExpanded?: boolean;
  };
  details: CustomSocialPostDetails;
}

export const CustomSocialPostCard = forwardRef(
  ({ commonData, details }: CustomSocialPostCardProps, ref) => {
    const { formatMessage } = useFormatters();

    return (
      <BaseSocialPostCard
        ref={ref}
        data={{
          ...commonData,
          tagText: formatMessage({
            description: "Social post card > custom post > tag",
            defaultMessage: "Announcement",
          }),
          tagColor: "primary",
          messageText: details.messageText,
          image: null, // no image supported for now
        }}
      />
    );
  }
);
