/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { RefObject, useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { VerificationCodeField } from "../../../../../../../../shared/verification-code-form/verification-code-field";

export interface RedeemDrawerVerifySmsFormProps {
  onSubmit(phoneNumber: string, wasPasted: boolean): Promise<void>;
  onResend?: () => void;
  onCancel: (() => void) | null;
  hasError: boolean;
  onClearErrorMessage?: () => void;
  footerRef: RefObject<HTMLDivElement>;
  footerType: "fixed" | "sticky";
}

export function RedeemDrawerVerifySmsForm({
  onSubmit,
  onResend,
  onCancel,
  hasError,
  onClearErrorMessage,
  footerRef,
  footerType,
}: RedeemDrawerVerifySmsFormProps) {
  const { formatMessage } = useFormatters();
  const [wasPasted, setWasPasted] = useState(false);

  const theme = useTheme();
  const [code, setCode] = useState("");

  const {
    handleSubmit,
    clearErrors,
    formState: { isSubmitting },
  } = useForm();

  return (
    <Form
      onSubmit={handleSubmit(() => onSubmit(code, wasPasted))}
      css={css`
        display: contents;
      `}
    >
      <div
        css={css`
          width: 100%;
          padding: 0 ${theme.spacing(2)};
        `}
      >
        <VerificationCodeField
          code={code}
          setCode={setCode}
          setWasPasted={setWasPasted}
          hasError={hasError}
          onClearErrorMessage={onClearErrorMessage}
        />
      </div>
      <div
        ref={footerRef}
        css={css`
          position: ${footerType};
          bottom: 0;
          left: 0;
          background-color: ${theme.palette.background.paper};
          width: 100%;
          padding: ${theme.spacing(2)};
        `}
      >
        <div
          css={(theme: AppTheme) =>
            css`
              display: flex;
              flex-direction: row;
              gap: ${theme.spacing(1)};
              > * {
                min-width: 160px;
                flex: 1;
              }
              flex-wrap: wrap;
            `
          }
        >
          {onCancel && (
            <Button
              type="button"
              onClick={onCancel}
              label={formatMessage({
                description:
                  "Redeem points drawer > verify sms > cancel button label",
                defaultMessage: "Cancel",
              })}
              variant="outlined"
              size="large"
            />
          )}
          <Button
            type="submit"
            label={formatMessage({
              description:
                "Redeem points drawer > verify sms > verify code button label",
              defaultMessage: "Verify code",
            })}
            color="primary"
            size="large"
            disabled={!/^\d{6}$/.test(code)}
            loading={isSubmitting}
          />
        </div>
        <Typography
          color={theme.palette.grey[800]}
          css={(appTheme: AppTheme) =>
            css`
              margin-top: ${appTheme.spacing(1)};
              text-align: center;
            `
          }
        >
          {onResend
            ? formatMessage(
                {
                  description:
                    "Redeem points drawer > verify sms > resend code",
                  defaultMessage:
                    "Didn't get a code? <resend_link>Resend</resend_link>",
                },
                {
                  resend_link: (nodes) => (
                    <button
                      type="button"
                      onClick={() => {
                        setCode("");
                        clearErrors();
                        onResend();
                      }}
                      css={(appTheme: AppTheme) =>
                        css`
                          color: ${appTheme.palette.primary.main};
                          outline: none;
                          border: none;
                          cursor: pointer;
                          background-color: transparent;
                        `
                      }
                    >
                      <Typography fontWeight={500}>{nodes}</Typography>
                    </button>
                  ),
                }
              )
            : null}
        </Typography>
      </div>
    </Form>
  );
}
