import { useEffect } from "react";

import { BaseSocialPostCardProps } from "@rewards-web/shared/components/social-post-card/base-social-post-card";
import { useOnScreenV2 } from "@rewards-web/shared/hooks/use-on-screen";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

/**
 * Hook to simplify infinite scroll loading.
 *
 * It returns a ref that must be passed to the last visible loaded item.
 *
 * When that last element is visible on screen, and there is a next page to load,
 * the provided `fetchNextPage` function is invoked.
 *
 * Note that `fetchNextPage` may be invoked multiple times (if the element
 * comes into view multiple times), so this should be handled accordingly.
 */
export function useInfiniteScrollRef({
  hasNextPage,
  fetchNextPage,
}: {
  hasNextPage: boolean;
  fetchNextPage(): void;
}) {
  const [onScreen, lastElementRef] = useOnScreenV2({
    threshold: 0.1, // triggered when _any_ part of the element is visible
  });

  useEffect(() => {
    if (onScreen && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen, hasNextPage]);

  return lastElementRef;
}

type BaseSocialCardPropsData = NonNullable<BaseSocialPostCardProps["data"]>;

export function useCommonBaseSocialPostCardProps(): Pick<
  BaseSocialCardPropsData,
  "showMoreText"
> {
  const { formatMessage } = useFormatters();

  const showMoreText = formatMessage({
    defaultMessage: "Show more",
    description: "Social Card > Show more text",
  });

  return { showMoreText };
}
