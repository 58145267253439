import * as Types from '@rewards-web/shared/graphql-types';

import { RedemptionCatalogItemFragmentFragment } from './redemption-catalog-item-fragment.generated';
import { gql } from '@apollo/client';
import { RedemptionCatalogItemFragmentFragmentDoc } from './redemption-catalog-item-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RedemptionCatalogItemOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RedemptionCatalogItemOptionsQuery = (
  { __typename?: 'Query' }
  & { getMyAvailableRedemptionCatalogItems: Array<(
    { __typename?: 'RedemptionCatalogItem' }
    & RedemptionCatalogItemFragmentFragment
  )> }
);


export const RedemptionCatalogItemOptionsDocument = gql`
    query RedemptionCatalogItemOptions {
  getMyAvailableRedemptionCatalogItems(categoryIn: [GIFT]) {
    ...RedemptionCatalogItemFragment
  }
}
    ${RedemptionCatalogItemFragmentFragmentDoc}`;

/**
 * __useRedemptionCatalogItemOptionsQuery__
 *
 * To run a query within a React component, call `useRedemptionCatalogItemOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRedemptionCatalogItemOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRedemptionCatalogItemOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRedemptionCatalogItemOptionsQuery(baseOptions?: Apollo.QueryHookOptions<RedemptionCatalogItemOptionsQuery, RedemptionCatalogItemOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RedemptionCatalogItemOptionsQuery, RedemptionCatalogItemOptionsQueryVariables>(RedemptionCatalogItemOptionsDocument, options);
      }
export function useRedemptionCatalogItemOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RedemptionCatalogItemOptionsQuery, RedemptionCatalogItemOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RedemptionCatalogItemOptionsQuery, RedemptionCatalogItemOptionsQueryVariables>(RedemptionCatalogItemOptionsDocument, options);
        }
export type RedemptionCatalogItemOptionsQueryHookResult = ReturnType<typeof useRedemptionCatalogItemOptionsQuery>;
export type RedemptionCatalogItemOptionsLazyQueryHookResult = ReturnType<typeof useRedemptionCatalogItemOptionsLazyQuery>;
export type RedemptionCatalogItemOptionsQueryResult = Apollo.QueryResult<RedemptionCatalogItemOptionsQuery, RedemptionCatalogItemOptionsQueryVariables>;