/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { Typography } from "@rewards-web/shared/components/typography";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { scrollToElement } from "../../../../shared/lib/scroll-to-element";
import { PointsIcon } from "../../../../shared/points-icon";
import {
  COUNT_UPANIMATION_DURATION_IN_MS,
  PointsCountUp,
} from "./points-count-up";

const PROGRESS_RADIUS = 133;

interface PointsProgressProps {
  pointsAvailableToRedeem: number;
  minimumPointsToRedeem: number;
  pointsPerDollar: number;
  redemptionMethods: RedemptionMethod[];
  email: string | undefined | null;
  phoneNumber: string | undefined | null;
}

export function PointsProgress({
  pointsAvailableToRedeem,
  minimumPointsToRedeem,
}: PointsProgressProps) {
  const { formatMessage } = useFormatters();
  const pastRedemptionsContainerRef = useRef<HTMLDivElement>(null);

  // initially set
  const [progressBarPct, setProgressBarPct] = useState(0);
  const pctUntilRedemption = Math.min(
    pointsAvailableToRedeem / minimumPointsToRedeem,
    1
  );
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => setProgressBarPct(pctUntilRedemption));
  }, [pctUntilRedemption]);

  // When a user redeem their points, we scroll to the top of the page, to show the confetti
  useEffect(() => {
    const justRedeemed = location.state?.justRedeemed || false;
    if (justRedeemed) {
      if (pastRedemptionsContainerRef.current) {
        scrollToElement(pastRedemptionsContainerRef.current);
      }
    }
  }, [location]);

  return (
    <>
      <div
        css={(appTheme: AppTheme) => css`
          padding: ${appTheme.spacing(5)} 0px;
          position: relative;
          display: flex;
          justify-content: center;
          background-color: ${appTheme.palette.primary.main};
          flex-direction: column;
          align-items: center;
        `}
        ref={pastRedemptionsContainerRef}
      >
        <div
          css={css`
            position: relative;
            display: flex;
            justify-content: center;
          `}
        >
          <div
            css={css`
              position: relative;
              overflow: hidden; /* Comment this line to understand the trick */
              width: ${PROGRESS_RADIUS * 2}px;
              height: ${PROGRESS_RADIUS}px; // cuts off bottom half
            `}
          >
            <div
              role="progressbar"
              aria-valuenow={pctUntilRedemption}
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                width: ${PROGRESS_RADIUS * 2}px;
                height: ${PROGRESS_RADIUS * 2}px;
                border-radius: 50%;
                transform: rotate(${45 + 180 * progressBarPct}deg);
                transition: ${COUNT_UPANIMATION_DURATION_IN_MS}ms ease-out
                  transform;
                box-sizing: border-box;
                border: 26px solid rgba(255, 255, 255, 0.3);
                border-bottom-color: white;
                border-right-color: white;
              `}
            />
          </div>
          <PointsIcon
            large
            css={css`
              width: 75px;
              position: absolute;
              bottom: 0;
            `}
          />
        </div>
        <PointsCountUp points={pointsAvailableToRedeem} />
        <Typography color="white" variant="subtitle">
          {formatMessage({
            defaultMessage: "Points",
            description: "Points summary page > number of points display",
          })}
        </Typography>
      </div>
    </>
  );
}
