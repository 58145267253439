/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

export function EarnMorePointsButton() {
  const { formatMessage } = useFormatters();

  return (
    <Button
      color="secondary"
      label={formatMessage({
        defaultMessage: "Earn more points",
        description:
          "Points summary page > redeem > earn more points button label",
      })}
      width="auto"
      linkTo={"/home"}
      css={(appTheme: AppTheme) => css`
        display: flex;
        padding: ${appTheme.spacing(1)} 0;
        margin-bottom: ${appTheme.spacing(3)};
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
      `}
    />
  );
}
