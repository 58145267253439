import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RewardsAppTabBadgeDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RewardsAppTabBadgeDataQuery = (
  { __typename?: 'Query' }
  & { lastDrawData?: Types.Maybe<(
    { __typename?: 'Draw' }
    & Pick<Types.Draw, 'id' | 'winnersAnnounced' | 'myRewardsUserViewedWinners'>
  )>, currentCaribouSponsoredDraw?: Types.Maybe<(
    { __typename?: 'CaribouSponsoredDraw' }
    & Pick<Types.CaribouSponsoredDraw, 'id' | 'myNumTicketsInDraw'>
  )>, organization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'minimumPointsNeededToRedeem' | 'drawsEnabled' | 'hasAccessToCaribouSponsoredDraws'>
  ), myRewardsUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'pointsRedeemed'>
  ), myUnreadSocialPostSummary: (
    { __typename?: 'UnreadSocialPostSummary' }
    & Pick<Types.UnreadSocialPostSummary, 'hasUnreadLatestPost'>
  ) }
);


export const RewardsAppTabBadgeDataDocument = gql`
    query RewardsAppTabBadgeData {
  lastDrawData: getLastDraw {
    id
    winnersAnnounced
    myRewardsUserViewedWinners
  }
  currentCaribouSponsoredDraw: getMyCurrentCaribouSponsoredDraw {
    id
    myNumTicketsInDraw
  }
  organization: getMyRewardsOrganization {
    id
    minimumPointsNeededToRedeem
    drawsEnabled
    hasAccessToCaribouSponsoredDraws
  }
  myRewardsUser: getMyRewardsUser {
    id
    pointsRedeemed
  }
  myUnreadSocialPostSummary: getMyUnreadSocialPostSummary {
    hasUnreadLatestPost
  }
}
    `;

/**
 * __useRewardsAppTabBadgeDataQuery__
 *
 * To run a query within a React component, call `useRewardsAppTabBadgeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsAppTabBadgeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsAppTabBadgeDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useRewardsAppTabBadgeDataQuery(baseOptions?: Apollo.QueryHookOptions<RewardsAppTabBadgeDataQuery, RewardsAppTabBadgeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsAppTabBadgeDataQuery, RewardsAppTabBadgeDataQueryVariables>(RewardsAppTabBadgeDataDocument, options);
      }
export function useRewardsAppTabBadgeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsAppTabBadgeDataQuery, RewardsAppTabBadgeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsAppTabBadgeDataQuery, RewardsAppTabBadgeDataQueryVariables>(RewardsAppTabBadgeDataDocument, options);
        }
export type RewardsAppTabBadgeDataQueryHookResult = ReturnType<typeof useRewardsAppTabBadgeDataQuery>;
export type RewardsAppTabBadgeDataLazyQueryHookResult = ReturnType<typeof useRewardsAppTabBadgeDataLazyQuery>;
export type RewardsAppTabBadgeDataQueryResult = Apollo.QueryResult<RewardsAppTabBadgeDataQuery, RewardsAppTabBadgeDataQueryVariables>;