import { reportError } from "@rewards-web/shared/modules/error";

import { useCreateMyRewardsUserAppDailyCheckInMutation } from "./graphql/create-my-rewards-user-app-daily-check-in.generated";

/**
 * Hook to record a user's daily check-in
 */
export function useRecordCheckIn() {
  const [
    createMyRewardsUserAppDailyCheckInMutation,
  ] = useCreateMyRewardsUserAppDailyCheckInMutation({
    onError: reportError,
  });

  return createMyRewardsUserAppDailyCheckInMutation;
}
