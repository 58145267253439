/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PointsIcon } from "../../../../../../../../shared/points-icon";

interface CatalogItemPointValueProps {
  locked?: boolean;
  priceInPoints: number;
}

export function CatalogItemPointValue({
  locked,
  priceInPoints,
}: CatalogItemPointValueProps) {
  const { formatMessage } = useFormatters();

  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        align-items: center;
        gap: ${theme.spacing(1)};
      `}
    >
      <PointsIcon
        css={
          locked &&
          css`
            filter: grayscale(100%);
          `
        }
      />{" "}
      <Typography variant="footnote" color="grey.600">
        {formatMessage(
          {
            defaultMessage:
              "<bold>{points, number}</bold> {points, plural, one {point} other {points}}",
            description: "Catalog item > number of points",
          },
          {
            points: priceInPoints,
            bold: (nodes) => (
              <span
                css={(theme: AppTheme) =>
                  css`
                    color: ${theme.palette.text.primary};
                  `
                }
              >
                {nodes}
              </span>
            ),
          }
        )}
      </Typography>
    </div>
  );
}
