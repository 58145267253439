/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/client";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Confetti,
  DEFAULT_CONFETTI_DURATION_MS,
} from "@rewards-web/shared/components/confetti";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";

import { usePointBalance } from "../../../../../shared/modules/point-balance";
import { PastRedemptionsDataDocument } from "../../past-redemptions/query.generated";
import { RedemptionCatalogItemFragmentFragment } from "../../redeem/catalog-giftcard-redemption-info/catalog-item-list/redemption-catalog-item-fragment.generated";
import { COUNT_UPANIMATION_DURATION_IN_MS } from "../points-count-up";
import { PaycheckRedeemPointsDrawer } from "./paycheck-redeem-points-drawer";
import { RedeemDrawer, RedeemDrawerProps } from "./redeem-drawer";

export interface RedeemPointsDrawerProps {
  open: boolean;
  onClose: () => void;
  pointsAvailableToRedeem: number;
  pointsPerDollar: number;
  email: string | undefined | null;
  phoneNumber: string | undefined | null;
  onExited?(): void;

  details:
    | {
        redemptionMethod: Exclude<RedemptionMethod, RedemptionMethod.Catalog>;
      }
    | {
        redemptionMethod: RedemptionMethod.Catalog;
        catalogItem: RedemptionCatalogItemFragmentFragment | undefined;
      };
}

export function RedeemPointsDrawer({
  open,
  onClose,
  onExited,
  pointsAvailableToRedeem,
  pointsPerDollar,
  email,
  phoneNumber,
  details,
}: RedeemPointsDrawerProps) {
  const [isShowingConfetti, setIsShowingConfetti] = useState(false);
  const confettiTimerRef = useRef<NodeJS.Timeout | null>(null);
  const onRedeemPointsTimerRef = useRef<NodeJS.Timeout | null>(null);
  const pointBalance = usePointBalance({ refreshOnMount: true });
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      confettiTimerRef.current && clearTimeout(confettiTimerRef.current);
      onRedeemPointsTimerRef.current &&
        clearTimeout(onRedeemPointsTimerRef.current);
    };
  }, []);

  useEffect(() => {
    if (isShowingConfetti) {
      const timer = setTimeout(
        () => setIsShowingConfetti(false),
        DEFAULT_CONFETTI_DURATION_MS + 1500
      );
      return () => clearTimeout(timer);
    }
  }, [isShowingConfetti]);

  const onRedeemed = () => {
    pointBalance.addLocalPoints(-pointsAvailableToRedeem);
    pointBalance.refreshPointBalance();
    apolloClient.refetchQueries({ include: [PastRedemptionsDataDocument] });
    confettiTimerRef.current = setTimeout(
      () => setIsShowingConfetti(true),
      COUNT_UPANIMATION_DURATION_IN_MS
    );
    onRedeemPointsTimerRef.current = setTimeout(
      () =>
        navigate("/my-rewards/past-redemptions", {
          state: { justRedeemed: true },
        }),
      0
    );
  };

  const modal = (() => {
    // we only support one redemption method at a time for now,
    // but this will be updated in an upcoming MR

    switch (details.redemptionMethod) {
      case RedemptionMethod.GenericGiftCardLink:
      case RedemptionMethod.Catalog:
        return (
          <RedeemDrawer
            email={email}
            phoneNumber={phoneNumber}
            open={open}
            onClose={onClose}
            onExited={onExited}
            onRedeemed={onRedeemed}
            pointsAvailableToRedeem={pointsAvailableToRedeem}
            details={details as RedeemDrawerProps["details"]}
          />
        );
      case RedemptionMethod.Payroll:
      case RedemptionMethod.Hsa:
      default: {
        return (
          <PaycheckRedeemPointsDrawer
            open={open}
            onClose={onClose}
            onRedeemed={onRedeemed}
            pointsAvailableToRedeem={pointsAvailableToRedeem}
            pointsPerDollar={pointsPerDollar}
            redemptionMethod={details.redemptionMethod}
          />
        );
      }
    }
  })();

  return (
    <>
      <Confetti active={isShowingConfetti} />
      {modal}
    </>
  );
}
