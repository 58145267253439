import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateMyRewardsUserAppDailyCheckInMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateMyRewardsUserAppDailyCheckInMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'createMyRewardsUserAppDailyCheckIn'>
);


export const CreateMyRewardsUserAppDailyCheckInDocument = gql`
    mutation CreateMyRewardsUserAppDailyCheckIn {
  createMyRewardsUserAppDailyCheckIn
}
    `;
export type CreateMyRewardsUserAppDailyCheckInMutationFn = Apollo.MutationFunction<CreateMyRewardsUserAppDailyCheckInMutation, CreateMyRewardsUserAppDailyCheckInMutationVariables>;

/**
 * __useCreateMyRewardsUserAppDailyCheckInMutation__
 *
 * To run a mutation, you first call `useCreateMyRewardsUserAppDailyCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyRewardsUserAppDailyCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyRewardsUserAppDailyCheckInMutation, { data, loading, error }] = useCreateMyRewardsUserAppDailyCheckInMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateMyRewardsUserAppDailyCheckInMutation(baseOptions?: Apollo.MutationHookOptions<CreateMyRewardsUserAppDailyCheckInMutation, CreateMyRewardsUserAppDailyCheckInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMyRewardsUserAppDailyCheckInMutation, CreateMyRewardsUserAppDailyCheckInMutationVariables>(CreateMyRewardsUserAppDailyCheckInDocument, options);
      }
export type CreateMyRewardsUserAppDailyCheckInMutationHookResult = ReturnType<typeof useCreateMyRewardsUserAppDailyCheckInMutation>;
export type CreateMyRewardsUserAppDailyCheckInMutationResult = Apollo.MutationResult<CreateMyRewardsUserAppDailyCheckInMutation>;
export type CreateMyRewardsUserAppDailyCheckInMutationOptions = Apollo.BaseMutationOptions<CreateMyRewardsUserAppDailyCheckInMutation, CreateMyRewardsUserAppDailyCheckInMutationVariables>;