/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";
import { StylableProps } from "@rewards-web/shared/types";

import { PointsIcon } from "../points-icon";

interface PointsPillProps extends StylableProps {
  points: number;

  /**
   * @default "tertiary"
   */
  color?: "tertiary" | "primary";
}

export function PointsPill({
  points,
  color = "tertiary",
  className,
}: PointsPillProps) {
  const { formatMessage } = useFormatters();

  return (
    <div
      className={className}
      css={(appTheme: AppTheme) => css`
        text-transform: uppercase;
        padding: ${appTheme.spacing(0.5)} ${appTheme.spacing(1.5)};
        border-radius: 500px;
        background-color: ${appTheme.palette[color].main};
        color: ${appTheme.palette[color].contrastText};
        display: flex;
        align-items: center;
        gap: ${appTheme.spacing(1.25)};
        width: fit-content;
      `}
    >
      <PointsIcon />
      <Typography variant="caption" color="white">
        {formatMessage(
          {
            defaultMessage: "{redeemable_points, number} points",
            description: "Redeem points drawer > number of points",
          },
          { redeemable_points: points }
        )}
      </Typography>
    </div>
  );
}
