import { useMediaQuery } from "@material-ui/core";
import { useReducer, useState } from "react";

import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { shouldBeNever } from "@rewards-web/shared/lib/should-be-never";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme, useAppTheme } from "@rewards-web/shared/style/theme";

import { useAuth } from "../../../../../../shared/modules/auth";
import { RedemptionCatalogItemFragmentFragment } from "../../../redeem/catalog-giftcard-redemption-info/catalog-item-list/redemption-catalog-item-fragment.generated";
import { CatalogItemDetail } from "./catalog-item-detail";
import { RedeemDrawerConfirmNewEmail } from "./confirm-new-email-step";
import { useRedeemDrawerIdentityQuery } from "./redeem-drawer-identity-query.generated";
import { RedeemDrawerRedeemStep } from "./redeem-step";
import { RedeemDrawerRedeemSuccess } from "./redeem-success-step";
import { RedeemDrawerRequireEmailDisclaimerStep } from "./require-email-disclaimer-step";
import { RedeemDrawerVerifySms } from "./verify-sms-step";

export interface RedeemDrawerProps {
  open: boolean;
  onClose: () => void;
  onExited?: () => void;
  onRedeemed: () => void;
  email: string | undefined | null;
  phoneNumber: string | null | undefined;
  pointsAvailableToRedeem: number;
  details:
    | {
        redemptionMethod: RedemptionMethod.GenericGiftCardLink;
      }
    | {
        redemptionMethod: RedemptionMethod.Catalog;
        catalogItem: RedemptionCatalogItemFragmentFragment;
      };
}

type RedeemStep =
  | "viewCatalogItemStep"
  | "redeemStep"
  | "emailDisclaimerStep"
  | "verifySmsStep"
  | "confirmNewEmailStep"
  | "redeemSuccessStep";

type RedeemState = {
  latestEmail: string;
  hasRedeemed: boolean;
  currentStep: RedeemStep;
};

type RedeemAction =
  | { type: "selectedCatalogItem" }
  | { type: "redeemedPoints" }
  | { type: "enterUpdateEmailFlow" }
  | { type: "verifiedSms" }
  | { type: "cancelVerifySms" }
  | { type: "cancelConfirmNewEmail" }
  | { type: "confirmNewEmail"; newEmail: string }
  | { type: "closedModal" };

const isEmailValid = (email: string) => !email.endsWith("@example.com");

export function RedeemDrawer({
  open,
  onClose,
  onExited,
  onRedeemed,
  email,
  phoneNumber,
  pointsAvailableToRedeem,
  details,
}: RedeemDrawerProps) {
  const { formatMessage } = useFormatters();
  const theme = useAppTheme();
  const snackbar = useSnackbar();
  const track = useTrack();
  const { getIsUserSmsVerified } = useAuth();
  const [footerHeight, setFooterHeight] = useState(0);
  const isMobileScreen = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down("xs")
  );

  const query = useRedeemDrawerIdentityQuery({
    fetchPolicy: "cache-first",
    onError: reportError,
  });
  const organizationId = query.data?.getMyRewardsOrganization.id;
  const userId = query.data?.getMyRewardsUser.id;
  const getIsCurrentUserSmsVerified = () =>
    userId ? getIsUserSmsVerified(userId) : false;

  /**
   * Returns the first step a user sees when they enter the update email flow.
   * They should skip the SMS verification step if they are already SMS verified.
   */
  const getEnterUpdateEmailFlowStep = (): Extract<
    RedeemStep,
    "confirmNewEmailStep" | "verifySmsStep"
  > =>
    getIsCurrentUserSmsVerified() ? "confirmNewEmailStep" : "verifySmsStep";

  /**
   * The update email flow business logic is to force a user to update their email if it is invalid.
   * However, this should only be enabled if the update email flow feature flag is enabled.
   */
  const getInitialStep = ({
    lastestEmail,
  }: {
    lastestEmail: string;
  }): Extract<
    RedeemStep,
    "emailDisclaimerStep" | "redeemStep" | "viewCatalogItemStep"
  > => {
    if (details.redemptionMethod === RedemptionMethod.Catalog) {
      // for catalog redemptions, always show the catalog item detail step first
      return "viewCatalogItemStep";
    }

    const shouldForceUpdateSms = !isEmailValid(lastestEmail);

    return shouldForceUpdateSms ? "emailDisclaimerStep" : "redeemStep";
  };

  const [state, dispatch] = useReducer(
    (state: RedeemState, action: RedeemAction): RedeemState => {
      switch (action.type) {
        case "selectedCatalogItem": {
          const shouldForceUpdateSms = !isEmailValid(state.latestEmail);

          return {
            ...state,
            currentStep: shouldForceUpdateSms
              ? "emailDisclaimerStep"
              : "redeemStep",
          };
        }
        case "redeemedPoints": {
          // note: this relies on the user updating their @example.com email in previous steps
          // (which they should be forced to do)
          return {
            ...state,
            currentStep: "redeemSuccessStep",
            hasRedeemed: true,
          };
        }
        case "enterUpdateEmailFlow":
          return {
            ...state,
            currentStep: getEnterUpdateEmailFlowStep(),
          };
        case "verifiedSms":
          return { ...state, currentStep: "confirmNewEmailStep" };
        case "cancelVerifySms":
        case "cancelConfirmNewEmail":
          return { ...state, currentStep: "redeemStep" };
        case "confirmNewEmail": {
          if (!isEmailValid(action.newEmail)) {
            // go back to disclaimer step if the new email is invalid
            // (this shouldn't really happen)
            return {
              ...state,
              currentStep: "emailDisclaimerStep",
              latestEmail: action.newEmail,
            };
          }

          return {
            ...state,
            currentStep: "redeemStep",
            latestEmail: action.newEmail,
          };
        }
        case "closedModal":
          return {
            ...state,
            currentStep: getInitialStep({
              lastestEmail: state.latestEmail,
            }),
            hasRedeemed: false,
          };
        default:
          shouldBeNever(action);
          return state;
      }
    },
    {
      currentStep: getInitialStep({
        lastestEmail: email ?? "",
      }),
      latestEmail: email ?? "",
      hasRedeemed: false,
    }
  );

  const closeModal = ({ hasRedeemed }: { hasRedeemed: boolean }) => {
    track("Closed giftcard redeem points drawer", { step: state.currentStep });
    if (hasRedeemed) {
      onRedeemed();
    }
    onClose();
  };

  const footerType = isMobileScreen ? "fixed" : "sticky";

  const content = (() => {
    switch (state.currentStep) {
      case "viewCatalogItemStep":
        return (
          <CatalogItemDetail
            catalogItem={
              (details as {
                redemptionMethod: RedemptionMethod.Catalog;
                catalogItem: RedemptionCatalogItemFragmentFragment;
              }).catalogItem
            }
            onCancel={() => closeModal({ hasRedeemed: false })}
            onSelect={() => dispatch({ type: "selectedCatalogItem" })}
            setFooterHeight={setFooterHeight}
            footerType={footerType}
          />
        );
      case "redeemStep":
        return (
          <RedeemDrawerRedeemStep
            pointsAvailableToRedeem={pointsAvailableToRedeem}
            latestEmail={state.latestEmail}
            isLatestEmailValid={isEmailValid(state.latestEmail)}
            onRedeemedPoints={() => {
              dispatch({ type: "redeemedPoints" });
            }}
            onEnterUpdateEmailFlow={() =>
              dispatch({ type: "enterUpdateEmailFlow" })
            }
            onCancel={() => closeModal({ hasRedeemed: state.hasRedeemed })}
            details={details}
            setFooterHeight={setFooterHeight}
            footerType={footerType}
          />
        );
      case "redeemSuccessStep":
        return (
          <RedeemDrawerRedeemSuccess
            redemptionMethod={details.redemptionMethod}
            onClose={() => closeModal({ hasRedeemed: state.hasRedeemed })}
            email={state.latestEmail}
            setFooterHeight={setFooterHeight}
            footerType={footerType}
          />
        );
      case "emailDisclaimerStep":
        return (
          <RedeemDrawerRequireEmailDisclaimerStep
            redemptionMethod={details.redemptionMethod}
            pointsAvailableToRedeem={pointsAvailableToRedeem}
            onEnterUpdateEmailFlow={() =>
              dispatch({ type: "enterUpdateEmailFlow" })
            }
            onCancel={() => closeModal({ hasRedeemed: state.hasRedeemed })}
            setFooterHeight={setFooterHeight}
            footerType={footerType}
          />
        );
      case "verifySmsStep":
        return (
          <RedeemDrawerVerifySms
            phoneNumber={phoneNumber}
            onCancel={
              isEmailValid(state.latestEmail)
                ? () => dispatch({ type: "cancelVerifySms" })
                : null
            }
            organizationId={organizationId!}
            onSmsVerified={() => {
              snackbar.show({
                severity: "success",
                position: "top",
                message: formatMessage({
                  defaultMessage: "Verification successful",
                  description:
                    "Redeem points drawer > redeemStep > verify sms alert",
                }),
              });
              dispatch({ type: "verifiedSms" });
            }}
            setFooterHeight={setFooterHeight}
            footerType={footerType}
          />
        );
      case "confirmNewEmailStep":
        return (
          <RedeemDrawerConfirmNewEmail
            userCurrentEmail={state.latestEmail}
            onCancel={
              isEmailValid(state.latestEmail)
                ? () => dispatch({ type: "cancelConfirmNewEmail" })
                : null
            }
            onConfirmNewEmail={(newEmail: string) => {
              snackbar.show({
                severity: "success",
                position: "top",
                message: formatMessage({
                  defaultMessage: "Updated email",
                  description:
                    "Redeem points drawer > redeemStep > updated email alert",
                }),
              });
              dispatch({ type: "confirmNewEmail", newEmail });
            }}
            setFooterHeight={setFooterHeight}
            footerType={footerType}
          />
        );
      default:
        shouldBeNever(state.currentStep);
        return null;
    }
  })();

  const title = (() => {
    switch (state.currentStep) {
      case "viewCatalogItemStep":
        return (
          (details as {
            redemptionMethod: RedemptionMethod.Catalog;
            catalogItem: RedemptionCatalogItemFragmentFragment;
          }).catalogItem?.displayName ?? ""
        );
      case "verifySmsStep":
        return formatMessage({
          description: "Redeem points drawer > verify sms > title",
          defaultMessage: "Verify it's you",
        });
      case "confirmNewEmailStep":
        return formatMessage({
          description: "Redeem points drawer > confirm new email > title",
          defaultMessage: "Change email",
        });
      case "redeemSuccessStep":
        return formatMessage({
          description: "Redeem points drawer > redeem success > title",
          defaultMessage: "Redemption success!",
        });
      default: {
        if (details.redemptionMethod === RedemptionMethod.Catalog) {
          return formatMessage({
            defaultMessage: "Confirm redemption",
            description:
              "Redeem points drawer > catalog item confirmation title",
          });
        }

        return formatMessage({
          defaultMessage: "Redeem points",
          description: "Redeem points drawer > title",
        });
      }
    }
  })();

  return (
    <ResponsiveDialog
      open={open}
      onClose={() => closeModal({ hasRedeemed: state.hasRedeemed })}
      onExited={() => {
        onExited?.();
        dispatch({ type: "closedModal" });
      }}
      maxWidth="430px"
      paddingBottom={isMobileScreen ? footerHeight : 0}
      title={title}
      titleVariant="h5"
      backgroundColor={theme.palette.background.paper}
    >
      {content}
    </ResponsiveDialog>
  );
}
