import { useState, useEffect, ReactNode } from "react";

import { openedFromHomeScreen } from "@rewards-web/shared/lib/opened-from-homescreen";
import {
  useClearAnalyticsIdentity,
  useSetAnalyticsIdentity,
  useTrack,
} from "@rewards-web/shared/modules/analytics";
import {
  useClearErrorTrackingIdentity,
  useSetErrorTrackingIdentity,
} from "@rewards-web/shared/modules/error";

import { useRecordCheckIn } from "../check-in";
import { AuthContext } from "./context";
import {
  getCognitoSession,
  getExistingCognitoUserId,
  signOutOfCognito,
} from "./legacy-cognito/lib";
import {
  getExistingUserId,
  getAccessToken as getAccessTokenFromStorage,
  clearAccessToken,
} from "./lib";

export { getCognitoAuthStorage } from "./legacy-cognito/auth-storage";

export * from "./hooks";

let currentLoggedInState: null | "loggedOut" | "loggedIn" = null;

/**
 * Auth state is tracked in memory so non-react parts can access it.
 */
export const AuthStatus = {
  isAuthenticated() {
    return currentLoggedInState === "loggedIn";
  },
};

export async function getAccessToken(): Promise<string> {
  try {
    const cognitoSession = await getCognitoSession();
    return cognitoSession.getAccessToken().getJwtToken();
  } catch (error) {
    // just ignore error -- if we can't get a cognito session token,
    // then we're not logged in via cognito

    return getAccessTokenFromStorage()!;
  }
}

export async function signOut() {
  clearAccessToken();
  await signOutOfCognito();
}

interface AuthProviderProps {
  children: ReactNode;
}

/**
 * This should wrap the application.
 *
 * It will render children after initialization (ie. it knows if the user is logged in or not)
 */
export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const track = useTrack();
  const setAnalyticsIdentity = useSetAnalyticsIdentity();
  const clearAnalyticsIdentity = useClearAnalyticsIdentity();
  const setErrorTrackingIdentity = useSetErrorTrackingIdentity();
  const clearErrorTrackingIdentity = useClearErrorTrackingIdentity();
  const [initialized, setInitialized] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);
  const recordCheckIn = useRecordCheckIn();

  /**
   * Note: On switching tenants (SelectedTenantProvider), the following is not re-run for the new tenant.
   * This is a known issue and we need to fix it.
   *
   */
  const handleIdentifiedUser = (userId: string) => {
    currentLoggedInState = "loggedIn";
    setAnalyticsIdentity(userId);
    setErrorTrackingIdentity(userId);
    setUserId(userId);
    recordCheckIn();
  };

  const handleUserLoggedOut = () => {
    currentLoggedInState = "loggedOut";
    clearAnalyticsIdentity();
    clearErrorTrackingIdentity();
    setUserId(null);
  };

  const initialize = async () => {
    const existingUserId =
      (await getExistingCognitoUserId()) ?? getExistingUserId();

    if (existingUserId) {
      handleIdentifiedUser(existingUserId);
      track("Re-opened app from previous login", {
        openedFromHomeScreen: openedFromHomeScreen(),
      });
    } else {
      handleUserLoggedOut();
    }

    setInitialized(true);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        userId,
        setUserId: (userId) => {
          if (userId) {
            handleIdentifiedUser(userId);
          } else {
            handleUserLoggedOut();
          }
        },
      }}
    >
      {initialized && children}
    </AuthContext.Provider>
  );
}
