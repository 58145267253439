import { ApolloError, useApolloClient } from "@apollo/client";
import { useContext, useState } from "react";

import { AnalyticsContext } from "@rewards-web/shared/modules/analytics/context";

import { AuthContext } from "./context";
import { RewardsAppAuthError, RewardsAppAuthErrorCode } from "./errors";
import {
  AuthenticateLoginTokenDocument,
  AuthenticateLoginTokenMutation,
  AuthenticateLoginTokenMutationVariables,
} from "./graphql/authenticate-login-token.generated";
import {
  CompleteSmsLoginChallengeDocument,
  CompleteSmsLoginChallengeMutation,
  CompleteSmsLoginChallengeMutationVariables,
} from "./graphql/complete-sms-login-challenge.generated";
import {
  CreateReauthChallengeDocument,
  CreateReauthChallengeMutation,
  CreateReauthChallengeMutationVariables,
} from "./graphql/create-reauth-challenge.generated";
import {
  CreateSmsLoginChallengeDocument,
  CreateSmsLoginChallengeMutation,
  CreateSmsLoginChallengeMutationVariables,
} from "./graphql/create-sms-login-challenge.generated";
import { signOutOfCognito } from "./legacy-cognito/lib";
import {
  clearAccessToken,
  getAccessToken,
  getUserCompletedSmsCodeChallengeFromAccessToken,
  getUserIdFromAccessToken,
  getUserIsImpersonatingFromAccessToken,
  saveAccessToken,
  saveAccessTokenForSession,
} from "./lib";

const CLIENT_ID = "rewards-app-client";

/**
 * Hook to abstract various auth needs
 */
export function useAuth() {
  const analytics = useContext(AnalyticsContext);
  const client = useApolloClient();
  const { userId, setUserId } = useContext(AuthContext);
  const signedIn = !!userId;
  const [
    phoneVerificationChallengeId,
    setPhoneVerificationChallengeId,
  ] = useState<string | null>(null);
  const accessToken = getAccessToken();

  return {
    userId,
    signedIn,
    phoneVerificationChallengeId,
    getUserIsImpersonating: () => {
      if (!accessToken) {
        return false;
      }

      return getUserIsImpersonatingFromAccessToken(accessToken);
    },
    getIsUserSmsVerified(rewardsUserId: string) {
      if (!accessToken) {
        return false;
      }
      /**
       * Return false if the token user ID is different from the access token.
       * This situation can arise when a caregiver belongs to
       *   multiple organizations and thus has multiple user IDs.
       */
      if (getUserIdFromAccessToken(accessToken) !== rewardsUserId) {
        return false;
      }
      return getUserCompletedSmsCodeChallengeFromAccessToken(accessToken);
    },
    async signOut() {
      clearAccessToken();

      // legacy -- log out of cognito
      await signOutOfCognito();
      setUserId(null);
    },

    async signInWithPhoneVerification(params: {
      phoneNumber: string;
      organizationId: string | null;
    }) {
      try {
        const createChallengeResult = await client.mutate<
          CreateSmsLoginChallengeMutation,
          CreateSmsLoginChallengeMutationVariables
        >({
          mutation: CreateSmsLoginChallengeDocument,
          variables: {
            phoneNumber: params.phoneNumber,
            organizationId: params.organizationId,
          },
        });
        setPhoneVerificationChallengeId(
          createChallengeResult.data!.createRewardsUserSMSLoginChallenge
            .challengeId
        );
      } catch (error) {
        if (error instanceof ApolloError) {
          if (
            error.graphQLErrors.some((error) =>
              error.message.includes("User is deactivated")
            )
          ) {
            throw new RewardsAppAuthError(
              "User is deactivated",
              RewardsAppAuthErrorCode.DEACTIVATED
            );
          }
        }

        throw error;
      }
    },

    async reauthWithPhoneVerification(params: {
      loginTokenId?: string;
      userId?: string;
    }) {
      try {
        const createChallengeResult = await client.mutate<
          CreateReauthChallengeMutation,
          CreateReauthChallengeMutationVariables
        >({
          mutation: CreateReauthChallengeDocument,
          variables: {
            loginTokenId: params.loginTokenId,
            userId: params.userId,
          },
        });
        const challengeId = createChallengeResult.data!
          .createRewardsUserTokenReauthChallenge.challengeId;

        setPhoneVerificationChallengeId(challengeId);

        return { challengeId };
      } catch (error) {
        if (error instanceof ApolloError) {
          if (
            error.graphQLErrors.some((error) =>
              error.message.includes("User is deactivated")
            )
          ) {
            throw new RewardsAppAuthError(
              "User is deactivated",
              RewardsAppAuthErrorCode.DEACTIVATED
            );
          }
        }

        throw error;
      }
    },

    async submitPhoneVerificationCode(code: string) {
      if (!phoneVerificationChallengeId) {
        throw new Error(
          "The user is not in a state to submit a verification code"
        );
      }

      try {
        const result = await client.mutate<
          CompleteSmsLoginChallengeMutation,
          CompleteSmsLoginChallengeMutationVariables
        >({
          mutation: CompleteSmsLoginChallengeDocument,
          variables: {
            clientId: CLIENT_ID,
            challengeId: phoneVerificationChallengeId,
            verificationCode: code,
          },
        });

        const accessToken = result.data!.completeRewardsUserSMSLoginChallenge
          .accessToken;
        saveAccessToken(accessToken);
        setUserId(getUserIdFromAccessToken(accessToken));
      } catch (error) {
        if (error instanceof ApolloError) {
          if (
            error.graphQLErrors.some((error) =>
              error.message.includes("User is deactivated")
            )
          ) {
            throw new RewardsAppAuthError(
              "User is deactivated",
              RewardsAppAuthErrorCode.DEACTIVATED
            );
          } else if (
            error.graphQLErrors.some((error) =>
              error.message.includes("Verification code is not correct")
            )
          ) {
            throw new RewardsAppAuthError(
              "Invalid verification code",
              RewardsAppAuthErrorCode.INVALID_VERIFICATION_CODE
            );
          } else if (
            error.graphQLErrors.some((error) =>
              error.message.includes(
                "Exceeded max attempts to complete challenge"
              )
            )
          ) {
            throw new RewardsAppAuthError(
              "Exceeded max attempts",
              RewardsAppAuthErrorCode.EXCEEDED_MAX_ATTEMPTS
            );
          } else if (
            error.graphQLErrors.some((error) =>
              error.message.includes("Challenge has expired")
            )
          ) {
            throw new RewardsAppAuthError(
              "User is deactivated",
              RewardsAppAuthErrorCode.CHALLENGE_EXPIRED
            );
          }
        }

        throw error;
      }
    },

    async signInWithRewardsInvitationToken(
      emailOrPhoneNumber: string,
      loginToken: string,
      loginTokenId?: string,
      impersonating: boolean = false
    ) {
      try {
        const result = await client.mutate<
          AuthenticateLoginTokenMutation,
          AuthenticateLoginTokenMutationVariables
        >({
          mutation: AuthenticateLoginTokenDocument,
          variables: {
            emailOrPhoneNumber,
            clientId: CLIENT_ID,
            loginToken,
            loginTokenId,
            impersonating,
          },
        });

        const accessToken = result.data!.authenticateRewardsUserLoginToken
          .accessToken;

        if (impersonating) {
          analytics.disable();
          clearAccessToken(); // clear the impersonator's access token from local storage
          saveAccessTokenForSession(accessToken); // save the impersonated user's access token for this session only
        } else {
          saveAccessToken(accessToken);
        }
        setUserId(getUserIdFromAccessToken(accessToken));
      } catch (error) {
        if (error instanceof ApolloError) {
          if (
            error.graphQLErrors.some((error) =>
              error.message.includes("User is deactivated")
            )
          ) {
            throw new RewardsAppAuthError(
              "User is deactivated",
              RewardsAppAuthErrorCode.DEACTIVATED
            );
          } else if (
            error.graphQLErrors.some((error) =>
              error.message.includes("Token authentication failed")
            )
          ) {
            throw new RewardsAppAuthError(
              "Token authentication failed",
              (() => {
                const errorFromApi = error.graphQLErrors.find((error) =>
                  error.message.includes("Token authentication failed")
                );

                switch (errorFromApi?.extensions?.reason) {
                  case "TOKEN_ALREADY_USED":
                    return RewardsAppAuthErrorCode.TOKEN_ALREADY_USED;
                  case "TOKEN_EXPIRED":
                    return RewardsAppAuthErrorCode.TOKEN_EXPIRED;
                  default:
                    return RewardsAppAuthErrorCode.TOKEN_AUTH_FAILED;
                }
              })()
            );
          }
        }

        throw error;
      }
    },
  };
}
