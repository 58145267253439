/** @jsxImportSource @emotion/react */
import { css, keyframes, useTheme } from "@emotion/react";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getContrastRatio } from "@material-ui/core";
import { useEffect, useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import HsaRedemptionImage from "../images/hsa-redemption.png";
import PaycheckRedemptionImage from "../images/paycheck-redemptions.png";
import { InsufficientPointsDrawer } from "../points-progress/insufficient-points-drawer";
import { COUNT_UPANIMATION_DURATION_IN_MS } from "../points-progress/points-count-up";
import { RedeemPointsDrawer } from "../points-progress/redeem-points-drawer";

interface PaycheckAndHSARedemptionCardProps {
  minimumPointsToRedeem: number;
  pointsPerDollar: number;
  phoneNumber: string | undefined | null;
  email: string | undefined | null;
  redemptionMethod: RedemptionMethod.Payroll | RedemptionMethod.Hsa;
  pointsAvailableToRedeem: number;
  isLocked: boolean;
}

const buttonAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export function PaycheckAndHSASelectorItem({
  minimumPointsToRedeem,
  pointsPerDollar,
  phoneNumber,
  email,
  redemptionMethod,
  pointsAvailableToRedeem,
  isLocked,
}: PaycheckAndHSARedemptionCardProps) {
  const { formatMessage } = useFormatters();
  const [isRedeemDrawerOpen, setIsRedeemDrawerOpen] = useState(false);
  const [
    isInsufficientPointsDrawerOpen,
    setIsInsufficientPointsDrawerOpen,
  ] = useState(false);

  const theme = useTheme();
  const track = useTrack();
  useEffect(() => {
    track(`Clicked ${redemptionMethod} redemption selector`, {
      methods: redemptionMethod,
    });
  }, [track, redemptionMethod]);
  const redeemButtonBackgroundColor =
    getContrastRatio(
      theme.palette.secondary.main,
      theme.palette.primary.main
    ) <= 1
      ? "tertiary"
      : "secondary";

  const cardTitle = (() => {
    switch (redemptionMethod) {
      case RedemptionMethod.Payroll:
        return formatMessage({
          defaultMessage: "Paycheck",
          description: "Points summary page > paycheck info > paycheck title",
        });
      case RedemptionMethod.Hsa:
        return formatMessage({
          defaultMessage: "HSA",
          description: "Points summary page > hsa info > hsa title",
        });
    }
  })();

  const cardDescription = (() => {
    switch (redemptionMethod) {
      case RedemptionMethod.Payroll:
        return formatMessage({
          defaultMessage: "Redeem rewards to your paycheck",
          description: "Points summary page > paycheck info > description",
        });

      case RedemptionMethod.Hsa:
        return formatMessage({
          defaultMessage: "Redeem rewards to your Health Spending Account",
          description: "Points summary page > hsa info > description",
        });
    }
  })();

  const cardImage = (() => {
    switch (redemptionMethod) {
      case RedemptionMethod.Payroll:
        return PaycheckRedemptionImage;
      case RedemptionMethod.Hsa:
        return HsaRedemptionImage;
    }
  })();

  return (
    <div
      css={(appTheme: AppTheme) => css`
        margin-top: ${appTheme.spacing(3)};
      `}
    >
      <RedeemPointsDrawer
        phoneNumber={phoneNumber}
        open={isRedeemDrawerOpen}
        onClose={() => setIsRedeemDrawerOpen(false)}
        pointsAvailableToRedeem={pointsAvailableToRedeem}
        pointsPerDollar={pointsPerDollar}
        details={{
          redemptionMethod,
        }}
        email={email}
      />
      <InsufficientPointsDrawer
        open={isInsufficientPointsDrawerOpen}
        onClose={() => setIsInsufficientPointsDrawerOpen(false)}
        pointsAvailableToRedeem={pointsAvailableToRedeem}
        minimumPointsToRedeem={minimumPointsToRedeem}
      />
      <div
        css={(appTheme: AppTheme) => css`
          padding: ${appTheme.spacing(2.5)};
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #f5f9fa;
          border-radius: 20px;
          margin-top: ${appTheme.spacing(3)};
        `}
      >
        <div>
          <Typography
            variant="h3"
            color={isLocked ? "grey.800" : "text.primary"}
            css={(appTheme: AppTheme) => css`
              display: flex;
              align-items: baseline;
              gap: ${appTheme.spacing(0.8)};
            `}
          >
            {isLocked && <FontAwesomeIcon icon={faLock} color="red" />}
            {cardTitle}
          </Typography>
          <Typography
            variant="footnote"
            css={(appTheme: AppTheme) => css`
              margin-top: ${appTheme.spacing(0.5)};
            `}
          >
            {cardDescription}
          </Typography>
          <Button
            css={(appTheme: AppTheme) => css`
              margin-top: ${appTheme.spacing(2)};
              ${!isLocked &&
              css`
                animation: ${buttonAnimation} 0.4s
                  ${COUNT_UPANIMATION_DURATION_IN_MS}ms linear;
              `}
            `}
            width="auto"
            label={formatMessage({
              defaultMessage: "Redeem now",
              description:
                "Points summary page > hsa and paycheck info > redeem button",
            })}
            disabled={isLocked}
            color={isLocked ? "tertiary" : redeemButtonBackgroundColor}
            onClick={() =>
              isLocked
                ? setIsInsufficientPointsDrawerOpen(true)
                : setIsRedeemDrawerOpen(true)
            }
          />
        </div>

        <img
          src={cardImage}
          alt={formatMessage({
            defaultMessage: "Paycheck redemption illustration",
            description: "Points summary page > paycheck info > image alt",
          })}
          css={css`
            width: 95px;
          `}
        />
      </div>
    </div>
  );
}
