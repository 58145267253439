/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { alpha } from "@material-ui/core";

import { AppTheme } from "@rewards-web/shared/style/types";

interface CatalogItemImageProps {
  imageUrl: string;
  displayName: string;
  autoWidth?: boolean;
  height: string;
}

export function CatalogItemImage({
  imageUrl,
  displayName,
  height,
  autoWidth,
}: CatalogItemImageProps) {
  return (
    <div
      css={(theme: AppTheme) => css`
        background: ${theme.palette.common.white};
        border: 1px solid ${alpha(theme.palette.grey[400], 0.9)};
        border-radius: 4px;
        width: ${autoWidth ? "auto" : "100%"};
        height: ${height};
        display: flex;
        align-items: center;
        justify-content: center;
        padding: ${theme.spacing(2)};
        margin-bottom: ${theme.spacing(1)};
      `}
      className="catalog-item-image-container"
    >
      <img
        src={imageUrl}
        alt={displayName}
        css={css`
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        `}
        className="catalog-item-image"
      />
    </div>
  );
}
