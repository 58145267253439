/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useOnScreenV2 } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { RedemptionCatalogItemFragmentFragment } from "../redemption-catalog-item-fragment.generated";
import { CatalogItemImage } from "./catalog-item-image";
import { CatalogItemPointValue } from "./catalog-item-point-value";

interface CatalogItemProps {
  item: RedemptionCatalogItemFragmentFragment;
  locked: boolean;
  onClick(): void;
}

export function CatalogItem({ item, locked, onClick }: CatalogItemProps) {
  const { formatMessage } = useFormatters();

  const [isOnScreen, ref] = useOnScreenV2<HTMLDivElement>({ threshold: 0.5 });
  const track = useTrack();
  const trackProps = useMemo(
    () => ({
      itemId: item.id,
      itemPriceInPoints: item.priceInPoints,
      itemDisplayName: item.displayName,
      locked,
    }),
    [item.id, item.displayName, item.priceInPoints, locked]
  );

  useEffect(() => {
    if (isOnScreen) {
      track("Catalog item viewed", trackProps);
    }
  }, [track, isOnScreen, trackProps]);

  const handleClick = () => {
    track("Redemption catalog item clicked", trackProps);
    onClick();
  };

  return (
    <div
      ref={ref}
      css={(theme: AppTheme) => css`
        cursor: pointer;

        & .catalog-item-image-container {
          transition: border-color 0.2s ease;
          background-color: ${theme.palette.background.paper};
        }

        &:hover .catalog-item-image-container {
          border-color: ${theme.palette.grey[600]};
        }

        & .catalog-item-display-name {
          transition: text-decoration 0.2s ease;
        }

        &:hover .catalog-item-display-name {
          text-decoration: underline;
        }
      `}
      onClick={handleClick}
    >
      <CatalogItemImage
        imageUrl={item.imageUrl}
        displayName={item.displayName}
        height="156px"
      />
      <Typography
        variant="subtitle"
        color={locked ? "grey.800" : "text.primary"}
      >
        {locked && <FontAwesomeIcon icon={faLock} color="red" />}{" "}
        <span className="catalog-item-display-name">{item.displayName}</span>
      </Typography>
      <Typography
        variant="caption"
        color="text.primary"
        textTransform="uppercase"
        css={(theme: AppTheme) => css`
          background-color: ${theme.palette.background.default};
          padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
          border-radius: 100px;
          width: fit-content;

          margin-top: ${theme.spacing(1)};
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        {formatMessage({
          defaultMessage: "Limited time",
          description: "Catalog item > limited time pill",
        })}
      </Typography>
      <CatalogItemPointValue
        locked={locked}
        priceInPoints={item.priceInPoints}
      />
    </div>
  );
}
